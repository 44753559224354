<template>
  <draggable
    :style="'width: 100%; '"
    v-bind="dragOptions"
    class="dragArea smart-wrap list-group fronter"
    :list="data"
    group="inputs"
    @change="log"
    item-key="id" 
  > 
    <template #item="{ element }">
      <div :class="'list-group-item miniShadow '" :style="'background:'+src.palette[element.style.bgc]+';'+setStyle(element)">
        <p data-toggle="collapse" :href="'#collapse-'+element.id" role="button" aria-expanded="false" aria-controls="collapseExample" :style="'display: flex; align-items: center;'+ setStyle(element)">
          <span style=" display: flex; flex-direction: column;">
            <span>
              <strong :style="setStyle(element)"> {{element.attributeName}} </strong> 
              &nbsp;&lt;{{ element.metaType == 'metaElement' ? element.elementType :'Input' }}&gt;&nbsp;
            </span>
            <span style=" display: flex; align-items: center;">
              <i>{{ element.class }}</i> 
              <svg title="Custom" v-if="typeof element.style.font.font == 'string' || typeof element.style.font.color == 'number' || typeof element.style.bgc == 'number'" style="padding: 0 2px; margin:0 5px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#868686" d="m11.4 18.161l7.396-7.396a10.289 10.289 0 0 1-3.326-2.234a10.29 10.29 0 0 1-2.235-3.327L5.839 12.6c-.577.577-.866.866-1.114 1.184a6.556 6.556 0 0 0-.749 1.211c-.173.364-.302.752-.56 1.526l-1.362 4.083a1.06 1.06 0 0 0 1.342 1.342l4.083-1.362c.775-.258 1.162-.387 1.526-.56c.43-.205.836-.456 1.211-.749c.318-.248.607-.537 1.184-1.114Zm9.448-9.448a3.932 3.932 0 0 0-5.561-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.092 3.32a8.754 8.754 0 0 0 3.431 2.13l.887-.887Z"/></svg>
              <svg title="Advance" v-if="element.styleWrap &&  element.styleComponent && ( element.styleWrap.length > 5 || element.styleComponent.length > 5)" style="padding: 0 2px; margin:0 5px;"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path  fill="#868686" d="M54.8 119.49a35.06 35.06 0 0 1-5.75 8.51a35.06 35.06 0 0 1 5.75 8.51C60 147.24 60 159.83 60 172c0 25.94 1.84 32 20 32a12 12 0 0 1 0 24c-19.14 0-32.2-6.9-38.8-20.51C36 196.76 36 184.17 36 172c0-25.94-1.84-32-20-32a12 12 0 0 1 0-24c18.16 0 20-6.06 20-32c0-12.17 0-24.76 5.2-35.49C47.8 34.9 60.86 28 80 28a12 12 0 0 1 0 24c-18.16 0-20 6.06-20 32c0 12.17 0 24.76-5.2 35.49ZM240 116c-18.16 0-20-6.06-20-32c0-12.17 0-24.76-5.2-35.49C208.2 34.9 195.14 28 176 28a12 12 0 0 0 0 24c18.16 0 20 6.06 20 32c0 12.17 0 24.76 5.2 35.49A35.06 35.06 0 0 0 207 128a35.06 35.06 0 0 0-5.75 8.51C196 147.24 196 159.83 196 172c0 25.94-1.84 32-20 32a12 12 0 0 0 0 24c19.14 0 32.2-6.9 38.8-20.51c5.2-10.73 5.2-23.32 5.2-35.49c0-25.94 1.84-32 20-32a12 12 0 0 0 0-24Z"/></svg>
            </span>
          </span>
          <div  class="btn btn-custom" style="opacity: 1; right: 60px;" v-on:click="setCustomItem({...element, open: true}); applyCustomItem([customItem,'clone'])"><i class='bx bxs-copy-alt'></i></div>
          <div  class="btn btn-custom" style="opacity: 1; right: 25px;" v-on:click="setCustomItem({...element, open: true}); applyCustomItem([customItem,'delete'])"><i class='bx bxs-trash-alt'></i></div>
          <div class="btn btn-custom" style="opacity: 1;" data-toggle="modal" :data-target="'#modal-custom'" v-on:click="setCustomItem(element);"><i class="bx bx-dots-vertical-rounded"></i></div>
        </p>
        <div :class="{'collapse':true, 'dragg':element.smartComponents.length != 0}" :id="'collapse-'+element.id">
          <paper-layers :src="src" :data="element.smartComponents"/>
        </div>
      </div>
    </template>
  </draggable>
</template>
<script>
//Tools
import Vuex from 'vuex'

//Components
import draggable from "vuedraggable";
import PaperDisplayer from './PaperDisplayer.vue';

export default {
  name:'paper-layers', 
  components:{
    draggable,
    PaperDisplayer,
  },
  props:{
    data:{
      type: Array,  
      default: () => []
    },
    src:{
      type: Array,  
      default: () => ['none']
    }
  },
  data() {
    return {
      
    }
  },
  created() {
  },
  methods: {
    ...Vuex.mapMutations(['setCustomItem','applyCustomItem']),
    log: function (evt) {
      console.log(evt);
    },
    setStyle(element){
      if (false) {
        let style = ''
        // Define la cadena de estilos CSS que deseas convertir en un objeto JavaScript
        const cssString = this.src.class
        // Crea una expresión regular para buscar los pares clave-valor
        const cssRegex = /\.([\w-]+)\s*\{([^}]+)\}/g;
        // Crea un objeto vacío para almacenar los estilos CSS
        const stylesObj = {};
        // Itera sobre las clases y los pares clave-valor y agrega cada propiedad y su valor al objeto
        let match;
        while ((match = cssRegex.exec(cssString)) !== null) {
          console.log(match[1]);
          const className = match[1];
          const classStyles = match[2];
          // Crea un objeto para almacenar los estilos de la clase
          const classObj = {};
          // Crea una expresión regular para buscar los pares clave-valor dentro de la clase
          const classRegex = /([\w-]+)\s*:\s*([^;]+);?/g;
          // Itera sobre los pares clave-valor de la clase y agrega cada propiedad y su valor al objeto
          let classMatch;
          while ((classMatch = classRegex.exec(classStyles)) !== null) {
            classObj[classMatch[1]] = classMatch[2].trim();
          }
          // Agrega la clase y sus estilos al objeto de estilos resultante
          stylesObj[className] = classObj;
  
          if (className == element.class) {
            style = stylesObj[className].background ? 'background:'+stylesObj[className].background +';':''
          }
        }
        const replacements = {
          'var(--color0)': this.src.palette[0],
          'var(--color1)': this.src.palette[1],
          'var(--color2)': this.src.palette[2],
          'var(--color3)': this.src.palette[3],
          'var(--color4)': this.src.palette[4],
          'var(--color5)': this.src.palette[5],
          'var(--color6)': this.src.palette[6],
          'var(--font0)': this.src.fonts[0],
          'var(--font1)': this.src.fonts[1],
          'var(--font2)': this.src.fonts[2],
          'var(--font3)': this.src.fonts[3],
        };
        const replacedString = style.replace(/var\(--(color|font)\d\)/g, match => replacements[match]);
        console.log(replacedString);
        return replacedString
      }
      let style = ''
      if (this.src.class && this.src.class != '') {
        let css = this.src.class
        const cssRules = css.split("}").filter(rule => rule.trim() !== "").map(rule => {
          const selector = rule.substring(0, rule.indexOf("{")).trim();
          const properties = rule.substring(rule.indexOf("{") + 1).split(";").filter(property => property.trim() !== "").map(property => {
            const splitIndex = property.indexOf(":");
            const key = property.substring(0, splitIndex).trim();
            const value = property.substring(splitIndex + 1).trim();
            if (element.class!='' && selector.startsWith('.'+element.class)) {
              style += key == 'font-family' ? property+';' :'';
              style += key == 'background' ? property+';' :'';
            }
            return { [key]: value };
          });
          return { [selector]: Object.assign({}, ...properties) };
        });
      }
      const replacements = {
        'var(--color0)': this.src.palette[0],
        'var(--color1)': this.src.palette[1],
        'var(--color2)': this.src.palette[2],
        'var(--color3)': this.src.palette[3],
        'var(--color4)': this.src.palette[4],
        'var(--color5)': this.src.palette[5],
        'var(--color6)': this.src.palette[6],
        'var(--font0)': this.src.fonts[0],
        'var(--font1)': this.src.fonts[1],
        'var(--font2)': this.src.fonts[2],
        'var(--font3)': this.src.fonts[3],
      };
      const replacedString = style.replace(/var\(--(color|font)\d\)/g, match => replacements[match]);
      return replacedString

    },
  },
  computed:{
    ...Vuex.mapState([
      "customItem",
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: "inputs",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  }
}
</script>
<style>

</style>