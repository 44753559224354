<template>
   <div class="col-calendar" >
      <div class="wrap-header">
         <div class="pre" v-on:click="actionCalendar('back', component.id); ">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#5a5a5a" fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm2.207 7.707a1 1 0 0 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L10.914 12l3.293-3.293Z" clip-rule="evenodd"/></svg>
         </div>
         <div class="info">
            <div class="head-month" :id="'month-'+component.id"></div>
            <div class="head-day" :id="'day-'+component.id"></div>
         </div>
         <div class="next" v-on:click="actionCalendar('next', component.id);">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g transform="translate(24 0) scale(-1 1)"><path fill="#5a5a5a" fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm2.207 7.707a1 1 0 0 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L10.914 12l3.293-3.293Z" clip-rule="evenodd"/></g></svg>
         </div>
      </div>
      <table :id="'calendar-'+component.id" class="preview-calendar" >
         <thead>
            <tr>
               <th>S</th>
               <th>M</th>
               <th>T</th>
               <th>W</th>
               <th>T</th>
               <th>F</th>
               <th>S</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="(week, windex) in 6" :key="windex" > 
               <td v-for="(day, dindex) in 7" :key="dindex" :id="'days-'+component.id" @click="(event) => setDay(event)">
                  <a>{{day}}</a>
               </td>
            </tr>
         </tbody>
      </table>
   </div>

</template>
<script>

//Tools
import { formatData, actionCalendar } from '../../store/tools.js';
//Components
import PaperDisplayer from '../papers/PaperDisplayer.vue'
   export default {
      name: "paper-table",
      components:{
         PaperDisplayer,
      },
      props:{
         data:{
            type: String,
            default: new Date()
            /*default: {
               day: new Date().getDate(),
               month:new Date().getMonth(),
               year:new Date().getFullYear(),
            }*/
         },
         component:{
            type: Object,
            default: {
               id:'1'
            }
         },
         group:{
            type: String,
            default: '1'
         },
      },
      data() {
         return {
         }
      },
      created() {
         let component = this.component.id
         setTimeout(() => {
            let date = {
               day: new Date(this.data).getDate(),
               month:new Date(this.data).getMonth(),
               year:new Date(this.data).getFullYear(),
            }
            actionCalendar('init', this.component.id, date)
         }, 100);
         
      },
      methods: {
         //Tools
         formatData(format, data){
            return formatData(format, data)
         },
         actionCalendar(action, id, data){
            console.log('data');
            data = {
               day: new Date(data).getDate(),
               month:new Date(data).getMonth(),
               year:new Date(data).getFullYear(),
            }
            
            actionCalendar(action, id, data)
         },
         setDay(event) {
            console.log(event.target.title);
            console.log(this.data);
            this.data = event.target.title
         },
         checkGroup(action, id){
            try {
               console.log(document.getElementById("calendar-"+id)); 
               switch (action) {
                  case '':
                     
                  break;
               }
               actionCalendar(action, id, data)
            } catch (error) {
               
            }
         },
         //Record Actions
      },
      computed: {
      },
      watch:{
         
      }
   }
</script>
