<template>
   <span v-if="data != '' && data != undefined" :id="id">
      <span id="if" v-if="type == 'combo' ">
         <span v-show="data != '' && item != ''"  :id="index" v-for="(item, index) in data" :key="index">
            <ui-recursive-display style="padding:0 0 0 5px;" v-if="index != 'PK'&& index != 'SK' && index != 'SK' "  :vdata="item" :format="format"/> 
         </span>
      </span>
      <span id="if" v-else-if="name  == 'sml.contactFullName'">
         <span v-show="data != '' && item != ''"  :id="index" v-for="(item, index) in data" :key="index">
            <ui-recursive-display style="padding:0 0 0 5px;" v-if="index != 'PK'&& index != 'SK' && index != 'SK' "  :vdata="item" :format="format"/> 
         </span>
      </span>
      <span id="if" v-else-if="typeof data == 'object' ">
         <span v-show="data != '' && item != ''"  style="display:block;" :id="index" v-for="(item, index) in data" :key="index">
            <ui-recursive-display style="padding:0 0 0 5px;" v-if="index != 'PK'&& index != 'SK' && index != 'SK' "  :vdata="item" :format="format"/> 
         </span>
      </span>
      <span v-else-if="vformat == 'date'">
         {{formatData(vformat, data)[0]}}<br>{{formatData(vformat, data)[1]}}
      </span>
      <span v-else-if="vformat == 'range'">
         <div class="progress" :style="'background:'+organizationData.colorSeriesA[1]+'!important;'">
         <div class="progress-bar bg-success" role="progressbar" :style="'width:'+data+'%; background:'+organizationData.colorSeriesA[0]+'!important; color:'+organizationData.colorSeriesA[1]+'!important;'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{data}}%</div>
         </div>
      </span>
      <span v-else-if="vformat == 'boolean'">
         <i v-if="data == true" class='bx bxs-check-circle' :style="'color:'+organizationData.colorSeriesA[0]+'; font-size:25px; opacity:0.9;'"></i>
      </span>
      <span v-else-if="vformat == 'phone'" class="absoluteCenterFlex">
         <span title="Open Virtual Phone">
            <svg  class="hoverOpacity" style="margin:0 10px 0 0;" v-on:click="openPhone" xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="#333" d="M24 13h-2a3.003 3.003 0 0 0-3-3V8a5.006 5.006 0 0 1 5 5Z"/><path fill="#333" d="M28 13h-2a7.008 7.008 0 0 0-7-7V4a9.01 9.01 0 0 1 9 9zm-7.667 8.482l2.24-2.24a2.167 2.167 0 0 1 2.337-.48l2.728 1.092A2.167 2.167 0 0 1 29 21.866v4.961a2.167 2.167 0 0 1-2.284 2.169C7.594 27.806 3.732 11.61 3.015 5.408A2.162 2.162 0 0 1 5.169 3h4.873a2.167 2.167 0 0 1 2.012 1.362l1.091 2.728a2.167 2.167 0 0 1-.48 2.337l-2.24 2.24s1.242 8.732 9.908 9.815z"/></svg>
         </span>
         <span title="Copy Phone Number">
            {{formatData(vformat, data)}} 
         </span>
      </span>
      <span v-else-if="vformat == 'html'" class="absoluteCenterFlex">
            
      </span>
      <span v-else>
         {{formatData(vformat, data)}} 
      </span>
   </span>
</template>
<script>
//Tools
import Vuex from "vuex";
import { Auth, API } from "aws-amplify";
import "amazon-connect-streams";
import { formatData,processBinds  } from '../../store/tools.js';

//Components
import PaperDisplayer from '../papers/PaperDisplayer.vue'

export default {
   name: "ui-recursive-display",
   components:{
      PaperDisplayer
   },
   props: {
      vdata:undefined,
      format:{
         type: String,
         default: 'text',
      },
      id:{
         type: String,
         default: 'text',
      },
      type:{
         type: String,
         default: null,
      },
      name:{
         type: String,
         default: null,
      }
   },
   data() {
      return {
         data: null,
         vformat: null,
      }
   },
   created() {
      try {
         if (this.format == '') {
            this.vformat = 'text'
         }else{
            this.vformat = this.format.toLowerCase()
         }
      } catch (error) {
         this.vformat = 'text'
      }
      switch (typeof this.vdata) {
         case 'object':
            this.data = Object.assign({}, this.vdata)
         break;
         default:
            this.data = this.vdata
         break;
      }
      this.$forceUpdate();
   },
   mounted() {
      if (this.vformat == 'html' && this.id != null && this.id != undefined ) {
         setTimeout(() => {
            document.getElementById(this.id).innerHTML = this.data
         }, 100);
      }
      
   },
   methods: {
      formatData(format, data){
         return formatData(format, data)
      },
      openPhone(){
         if (document.getElementById('callBox').classList[1]) {
            document.getElementById('callBox').classList.remove('show')
         }else{
            document.getElementById('callBox').classList.add('show')
         }
      },
   },
   computed: {
      ...Vuex.mapState(['organizationData']),
   },
}
</script>