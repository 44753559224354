<template>
  <div :class="'plEditor pl-editor-'+name">
    <div class="toolbar">
      <div class="line">
        <div class="box">
          <span class="editor-btn icon smaller" data-action="bold" data-tag-name="b" title="Bold">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/bold.png"/>
          </span>
          <span class="editor-btn icon smaller" data-action="italic" data-tag-name="i" title="Italic">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/italic.png"/>
          </span>
          <span class="editor-btn icon smaller" data-action="underline" data-tag-name="u" title="Underline">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/underline.png"/>
          </span>
          <span class="editor-btn icon smaller" data-action="strikeThrough" data-tag-name="strike" title="Strike through">
            <img src="https://img.icons8.com/fluency-systems-filled/30/000000/strikethrough.png"/>
          </span>
        </div>
        <div class="box">
          <span class="editor-btn icon has-submenu">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/align-left.png"/>
            <div class="submenu">
              <span class="editor-btn icon" data-action="justifyLeft" data-style="textAlign:left" title="Justify left">
                <img src="https://img.icons8.com/fluency-systems-filled/48/000000/align-left.png"/>
              </span>
              <span class="editor-btn icon" data-action="justifyCenter" data-style="textAlign:center" title="Justify center">
                <img src="https://img.icons8.com/fluency-systems-filled/48/000000/align-center.png"/>
              </span>
              <span class="editor-btn icon" data-action="justifyRight" data-style="textAlign:right" title="Justify right">
                <img src="https://img.icons8.com/fluency-systems-filled/48/000000/align-right.png"/>
              </span>
              <span class="editor-btn icon" data-action="formatBlock" data-style="textAlign:justify" title="Justify block">
                <img src="https://img.icons8.com/fluency-systems-filled/48/000000/align-justify.png"/>
              </span>
            </div>
          </span>
          <span class="editor-btn icon" data-action="insertOrderedList" data-tag-name="ol" title="Insert ordered list">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/numbered-list.png"/>
          </span>
          <span class="editor-btn icon" data-action="insertUnorderedList" data-tag-name="ul" title="Insert unordered list">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/bulleted-list.png"/>
          </span>
          <span class="editor-btn icon" data-action="outdent" title="Outdent" data-required-tag="li">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/outdent.png"/>
          </span>
          <span class="editor-btn icon" data-action="indent" title="Indent">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/indent.png"/>
          </span>
          
        </div>
        <div class="box">
          <span class="editor-btn icon" data-action="insertHorizontalRule" title="Insert horizontal rule">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/horizontal-line.png"/>
          </span>
        </div>
        <div class="box save">
          <slot name="save">
            
          </slot>
        </div>
        
      </div>
      <div class="line">
        <div class="box">
          <span class="editor-btn icon smaller" data-action="undo" title="Undo">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/undo--v1.png"/>
          </span>
          <span class="editor-btn icon" data-action="toggle-view" :id="'pl-change-'+name" title="Show HTML-Code">
            <img src="https://img.icons8.com/fluency-systems-filled/48/000000/source-code.png"/>
          </span>
        </div>
      </div>
    </div>
    <div class="content-area">
      <slot name="html">
        <div class="visuell-view" v-on:keyup="checkChange('pl-save-'+name)" :id="'pl-editor-'+name" contenteditable>
        </div>
      </slot>
      <slot name="bind">
        <textarea class="html-view"  spellcheck="false"></textarea>
      </slot>
    </div>
  </div>
</template>
<script>
//Tools
import "amazon-connect-streams";
//import { formatData,processBinds  } from '../../store/tools.js';
export default {
  name: "pl-editor",
  props: {
    name:{
      type: String,
      default: 'default',
    },
  },  
  data() {
    return {
      editor: null,
    }
  },
  created() {
    //console.clear()
    //console.log(this.name);
    setTimeout(() => {
      const editor = document.getElementsByClassName('pl-editor-'+this.name)[0];
      const toolbar = editor.getElementsByClassName('toolbar')[0];
      const buttons = toolbar.querySelectorAll('.editor-btn:not(.has-submenu)');
      const contentArea = editor.getElementsByClassName('content-area')[0];
      const visuellView = contentArea.getElementsByClassName('visuell-view')[0];
      const htmlView = contentArea.getElementsByClassName('html-view')[0];
      const modal = document.getElementsByClassName('modal')[0];

      // add active tag event
      document.addEventListener('selectionchange', selectionChange);

      // add paste event
      visuellView.addEventListener('paste', pasteEvent);

      // add paragraph tag on new line
      contentArea.addEventListener('keypress', addParagraphTag);

      // add toolbar button actions
      for(let i = 0; i < buttons.length; i++) {
        let button = buttons[i];
        
        button.addEventListener('click', function(e) {
          let action = this.dataset.action;
          //console.log(action);
          switch(action) {
            case 'toggle-view':
              execCodeAction(this, editor);
              break;
            case 'createLink':
              execLinkAction();
              break;
            default:
              execDefaultAction(action);
          }
          
        });
      }
      
      function execCodeAction(button, editor) {
        //console.log(button);
        if(button.classList.contains('active')) { // show visuell view
          visuellView.innerHTML = htmlView.value;
          htmlView.style.display = 'none';
          visuellView.style.display = 'block';

          button.classList.remove('active');     
        } else {  // show html view
          htmlView.value = visuellView.innerHTML;
          visuellView.style.display = 'none';
          htmlView.style.display = 'block';

          button.classList.add('active'); 
        }
      }

      /**
       * This function adds a link to the current selection
       */
      function execLinkAction() {  
        modal.style.display = 'block';
        let selection = saveSelection();

        let submit = modal.querySelectorAll('button.done')[0];
        let close = modal.querySelectorAll('.close')[0];
        
        // done button active => add link
        submit.addEventListener('click', function(e) {
          e.preventDefault();
          let newTabCheckbox = modal.querySelectorAll('#new-tab')[0];
          let linkInput = modal.querySelectorAll('#linkValue')[0];
          let linkValue = linkInput.value;
          let newTab = newTabCheckbox.checked;    
          
          restoreSelection(selection);
          
          if(window.getSelection().toString()) {
            let a = document.createElement('a');
            a.href = linkValue;
            if(newTab) a.target = '_blank';
            window.getSelection().getRangeAt(0).surroundContents(a);
          }

          modal.style.display = 'none';
          linkInput.value = '';
          
          // deregister modal events
          submit.removeEventListener('click', arguments.callee);
          close.removeEventListener('click', arguments.callee);
        });  
        
        // close modal on X click
        close.addEventListener('click', function(e) {
          e.preventDefault();
          let linkInput = modal.querySelectorAll('#linkValue')[0];
          
          modal.style.display = 'none';
          linkInput.value = '';
          
          // deregister modal events
          submit.removeEventListener('click', arguments.callee);
          close.removeEventListener('click', arguments.callee);
        });
      }

      /**
       * This function executes all 'normal' actions
       */
      function execDefaultAction(action) {
        document.execCommand(action, false);
      }

      /**
       * Saves the current selection
       */
      function saveSelection() {
          if(window.getSelection) {
              sel = window.getSelection();
              if(sel.getRangeAt && sel.rangeCount) {
                  let ranges = [];
                  for(var i = 0, len = sel.rangeCount; i < len; ++i) {
                      ranges.push(sel.getRangeAt(i));
                  }
                  return ranges;
              }
          } else if (document.selection && document.selection.createRange) {
              return document.selection.createRange();
          }
          return null;
      }

      /**
       *  Loads a saved selection
       */
      function restoreSelection(savedSel) {
          if(savedSel) {
              if(window.getSelection) {
                  sel = window.getSelection();
                  sel.removeAllRanges();
                  for(var i = 0, len = savedSel.length; i < len; ++i) {
                      sel.addRange(savedSel[i]);
                  }
              } else if(document.selection && savedSel.select) {
                  savedSel.select();
              }
          }
      }

      /**
       * Sets the current selected format buttons active/inactive
       */ 
      function selectionChange(e) {
        
        for(let i = 0; i < buttons.length; i++) {
          let button = buttons[i];
          
          // don't remove active class on code toggle button
          if(button.dataset.action === 'toggle-view') continue;
          
          button.classList.remove('active');
        }
        
        if(!childOf(window.getSelection().anchorNode.parentNode, editor)) return false;
        
        parentTagActive(window.getSelection().anchorNode.parentNode);
      }

      /**
       * Checks if the passed child has the passed parent
       */
      function childOf(child, parent) {
        return parent.contains(child);
      }

      /**
       * Sets the tag active that is responsible for the current element
       */
      function parentTagActive(elem) {
        if(!elem ||!elem.classList || elem.classList.contains('visuell-view')) return false;
        
        let toolbarButton;
        
        // active by tag names
        let tagName = elem.tagName.toLowerCase();
        toolbarButton = document.querySelectorAll(`.toolbar .editor-btn[data-tag-name="${tagName}"]`)[0];
        if(toolbarButton) {
          toolbarButton.classList.add('active');
        }
        
        // active by text-align
        let textAlign = elem.style.textAlign;
        toolbarButton = document.querySelectorAll(`.toolbar .editor-btn[data-style="textAlign:${textAlign}"]`)[0];
        if(toolbarButton) {
          toolbarButton.classList.add('active');
        }
        
        return parentTagActive(elem.parentNode);
      }

      /**
       * Handles the paste event and removes all HTML tags
       */
      function pasteEvent(e) {
        e.preventDefault();
        
        let text = (e.originalEvent || e).clipboardData.getData('text/plain');
        document.execCommand('insertHTML', false, text);
      }

      /**
       * This functions adds a paragraph tag when the enter key is pressed
       */
      function addParagraphTag(evt) {
        if (evt.keyCode == '13') {
          
          // don't add a p tag on list item
          if(window.getSelection().anchorNode.parentNode.tagName === 'LI') return;
          document.execCommand('formatBlock', false, 'p');
        }
      }
    }, 500);
  },
  methods:{
    checkChange(id){
      document.getElementById(id).classList.add('save-btn')
      console.log(document.getElementById(id));
    }
  },
};
</script>
<style lang="less">
  .plEditor {
    width: 100%;
    min-height: 18rem;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    position: relative;
    .toolbar {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

        .line {
          display: flex;
          border-bottom: 1px solid #e2e2e2;

          &:last-child {
              border-bottom: none;
          }

          .box {
              display: flex;
              border-left: 1px solid #e2e2e2;
              
              &.save{
                position: absolute;
                right: 0;
                display: block;
              }
              .editor-btn {
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                transition: .2s ease all;
                &:hover,
                &.active {
                    background-color: #e1e1e1;
                    cursor: pointer;
                }

                &.icon img {
                    width: 15px;
                    padding: 9px;
                    box-sizing: content-box;
                }
                &.icon svg {
                    width: 15px;
                    padding: 9px;
                    box-sizing: content-box;
                }

                &.icon.smaller img {
                    width: 16px;
                }

                &.has-submenu {
                    width: 20px;
                    padding: 0 10px;

                    &::after {
                      content: '';
                      width: 6px;
                      height: 6px;
                      position: absolute;
                      background-image: url(https://img.icons8.com/ios-glyphs/30/000000/chevron-down.png);
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: center;
                      right: 4px;
                    }

                    .submenu {
                      display: none;
                      position: absolute;
                      top: 34px;
                      left: -1px;
                      z-index: 10;
                      background-color: #FFF;
                      border: 1px solid #b5b5b5;
                      border-top: none;

                      .btn {
                          width: 39px;
                      }

                      &:hover {
                          display: block;
                      }
                    }

                    &:hover .submenu {
                      display: block;
                    }
                }
              }
          }
        }
    }

    .content-area {
        padding: 15px 12px;
        line-height: 1.5;
        .visuell-view {
          outline: none;
          min-height: 12rem;
          p {
              margin: 12px 0;
          }
        }
        .html-view {
          //outline: none;
          display: none;
          width: 100%;
          min-height: 250px;
          //height: 200px;
          border: none;
          //resize: none;
        }
    }
  }


</style>
