<template>
  <div class="smart-wrap" v-if="layout != null && models != null" >
    <div :class="setClass(component)+' '+component.class" :style="setStyle(component, 'wrap')" :id="component.id"  v-for="(component, index) in layout" :key="index" data-toggle="tooltip" data-placement="top" title="">
      <h1 v-if="component.metaType == 'Tag'">{{component.attributeName+responsive}}</h1>
      <form class="smart-wrap" v-if="component.elementType == 'paymentMethod'" action="" :id="'contactForm-'+responsiveText"  :style="setStyle(component, 'component')">
        <div class="demo-frame " style="width: 100%;">
          <h4 >Amount:{{models.amount}}</h4>
          <form action="/" method="post"  id="cardForm" autocomplete="off">
            <label class="hosted-fields--label" for="card-number">Card Number</label>
            <div id="card-number" class="hosted-field"></div>
            <label class="hosted-fields--label" for="expiration-date">Expiration Date</label>
            <div id="expiration-date" class="hosted-field"></div>
  
            <label class="hosted-fields--label" for="cvv">CVV</label>
            <div id="cvv" class="hosted-field"></div>
  
            <label class="hosted-fields--label" for="postal-code">Postal Code</label>
            <div id="postal-code" class="hosted-field"></div>
  
            <div class="button-container">
            <br>
            <div class="input-wrap col-12" style="text-align: left;">
              <input v-if="$router.currentRoute.value.path != '/sales'" type="checkbox" class="box" />
              <span v-if="$router.currentRoute.value.path != '/sales'"  class="text" style="font-size:12px;display: block;float: right;width: calc(100% - 20px); padding: 0 0 0 10px;" > Save this CC for future transactions </span>
              <br>
              <input  type="checkbox" class="box" v-model="tandc"/>
              <span v-if="$router.currentRoute.value.path != '/sales'"  class="text" style="font-size:12px;display: block;float: right;width: calc(100% - 20px); padding: 0 0 0 10px;" >I agree to the <a :href="publicOrganizationData.termsAndConditions" target="_blank">terms and conditions</a></span>
              <span v-if="$router.currentRoute.value.path == '/sales'" class="text" style="font-size:12px;display: block;float: right;width: calc(100% - 20px); padding: 0 0 0 10px;" >I agree to the terms and conditions</span>
            </div>
            <br>
            <input v-if="tandc == true" type="submit" class="btn-g show green center" :value="$router.currentRoute.value.path != '/sales' ? 'Save' : 'Pay'" style="cursor:pointer;" id="submit" v-on:click="action()"  />
            <input v-else  class="btn-g show gray center" style="cursor:pointer;" value="Save" disabled />
            </div>
          </form>
        </div>
      </form>
      <span v-if="component.metaType != 'metaElement' && component.usersCanEdit.includes('@client') != true  && component.metaType != 'Tag'">
        {{ deleteComplete(component.attributeName) }}
      </span>
      <div v-if="component.metaType != 'metaElement' && component.inputType != 'calendar' && component.metaType != 'Tag' && component.metaType != 'paymentMethod'" class="label">
        <!--Clip-->
        <div v-if="layoutCategory == 'smartForm' && component.smartInput == true && !component.usersCanEdit.includes('@client') != true" class="dropdown show labelOptions dropright">
          <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor:pointer;">
            <svg  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="forestgreen" fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Z"/></svg>
          </a>
          <div class="dropdown-menu options " aria-labelledby="dropdownMenuLink">
            <div class="input-wrap col-12">
              <p>Complete</p>
              <input type="checkbox" name="" id="" v-model="models[component.attributeName].complete">
            </div>
            <div class="input-wrap col-12">
              <label>Notes</label>
              <textarea name="" id="" cols="30" rows="5" v-model="models[component.attributeName].notes"></textarea>
            </div>
          </div>
        </div>
        <!--Label-->
        <strong v-if="component.order">{{component.order}}.&nbsp; </strong>
        <label > {{component.label}}</label>
        <!--Help-->
        <div v-if="component.help != '' && component.help != undefined && component.help != ' ' && component.help != 'undefined' && component.metaType != 'metaElement'" class="dropdown show labelOptions">
          <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg  v-on:click="help(component)"  width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
              <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
              <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
              <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
            </svg>
          </a>
          <div class="dropdown-menu options" aria-labelledby="dropdownMenuLink" style="padding:20px;">
            <span :id="'help'+component.id"> {{ component.help }}</span>
          </div>
        </div>
        
        <!--Dont-->
        <div v-if="component.smartInput == true && component.usersCanEdit.includes('@client') && component.readOnly != true && ( models[component.attributeName] && models[component.attributeName].complete != true)" class="dropdown show labelOptions">
          &nbsp;
          <span style="font-size:14px;">Don't have</span>
          <input style="width: 30px; top: 5px; height: 20px;" type="checkbox" name="" id="" v-model="models[component.attributeName].dontHave">
          <span style="font-size:14px;">Don't know</span>
          <input style="width: 30px; top: 5px; height: 20px;" type="checkbox" name="" id="" v-model="models[component.attributeName].dontKnow">
        </div>
      </div> 
      <p style="font-size: 12px;" >{{component.description}}</p>
      <!--Coments-->
      <div v-if="component.metaType != 'metaElement' && component.smartInput == true && !component.usersCanEdit.includes('@client') != true  && component.metaType != 'Tag'" class="dropdown show labelOptions">
        <a style="color:#fff; font-size: 14px; font-weight: 400; background:#0049c9; padding:3px 3px 0px;; border-radius:5px; cursor:pointer;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="white" d="M924.3 338.4a447.57 447.57 0 0 0-96.1-143.3a443.09 443.09 0 0 0-143-96.3A443.91 443.91 0 0 0 512 64h-2c-60.5.3-119 12.3-174.1 35.9a444.08 444.08 0 0 0-141.7 96.5a445 445 0 0 0-95 142.8A449.89 449.89 0 0 0 65 514.1c.3 69.4 16.9 138.3 47.9 199.9v152c0 25.4 20.6 46 45.9 46h151.8a447.72 447.72 0 0 0 199.5 48h2.1c59.8 0 117.7-11.6 172.3-34.3A443.2 443.2 0 0 0 827 830.5c41.2-40.9 73.6-88.7 96.3-142c23.5-55.2 35.5-113.9 35.8-174.5c.2-60.9-11.6-120-34.8-175.6zM312.4 560c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48s47.9 21.5 47.9 48s-21.4 48-47.9 48zm199.6 0c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48s47.9 21.5 47.9 48s-21.5 48-47.9 48zm199.6 0c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48s47.9 21.5 47.9 48s-21.5 48-47.9 48z"/></svg>
          <span style="font-weight: 500;">&nbsp;Add Comments: </span>
        </a>
        {{models[component.attributeName].comments}}
        <div class="dropdown-menu options" aria-labelledby="dropdownMenuLink">
          <div class="input-wrap col-12">
            <label>Comments</label>
            <textarea name="" id="" cols="30" rows="5" v-model="models[component.attributeName].comments"></textarea>
          </div>
        </div>
      </div>
      <p  v-if="component.metaType != 'metaElement' && !component.usersCanEdit.includes('@client') != true && models[component.attributeName].notes != undefined && models[component.attributeName].notes != ''  && component.metaType != 'Tag'" style="color:#F23D3D; font-size: 12px; font-weight: 600;">
        <i class='bx bxs-bell-ring' style='color:#ff0000'  ></i> 
        <span style="font-weight: 700;">Specialist Comments: </span> 
        {{models[component.attributeName].notes}}
      </p>
      <!-- Inputs -->
      <div  v-if="conditionInput('edit',component) && component.metaType == 'metaInput' "  :style="setStyle(component,'component')">
        <!-- Text -->
        <input v-if="component.inputType == 'Text' || component.inputType == 'Date' || component.inputType == 'Number' || component.inputType == 'Email' || component.inputType == 'Counter'  || component.inputType == 'ZipCode'"  :type="component.inputType" v-model="models[component.attributeName].attributeValue" :required="component.required" />
        <input v-if="component.inputType == null"  :type="text" v-model="component.attributeName" :required="component.required" />
        <input v-if="component.inputType == 'createdBy'" disabled :type="component.inputType" v-model="models[component.attributeName].attributeValue"  />
        
        <!-- Phone -->
        <span v-if="component.inputType == 'Phone' && ( data.SK == '' || !data.SK.startsWith('PHO') )" > {{ bindComposer(component.attributeName, 'phone') }} </span>
        <input v-if="component.inputType == 'Phone' &&( data.SK == '' || !data.SK.startsWith('PHO') )" type="text" v-on:keyup=" (event) => {inputPhone(component.attributeName, 'n',event)}" v-model="phone[component.attributeName]">
        <span v-if="component.inputType == 'Phone' && data.SK != '' && data.SK.startsWith('PHO') ">{{formatData('phone',models[component.attributeName].attributeValue)}}</span>
        
        <span v-if="component.inputType == 'Currency'" > {{ bindComposer(component.attributeName, 'currency') }} </span>
        <input v-if="component.inputType == 'Currency'" type="text" v-on:keyup="inputCurrency(component.attributeName);" v-model="currency[component.attributeName]" style="text-align:end;">
        
        <!-- SELECT -->
        <select  :style="f_styleInpu(index)" v-on:change="inputAction( component , 'standard')" v-if="component.inputType == 'Select' && ( !component.computed || component.computed == false) " v-model="models[component.attributeName].attributeValue">
          <option v-for="(option, index) in component.valueOptions" :key="index" :value="option">{{ option }} </option>
        </select>
        <div  v-if="component.inputType == 'Select' && ( component.computed && component.computed == true) " class="dropdown show">
          <a  class="dropdown-toggle inputMimo" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 10px; cursor:pointer;" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span v-if="models[component.attributeName].attributeValue"> {{ models[component.attributeName].attributeValue[Object.keys( models[component.attributeName].attributeValue)[0]] }} </span>
            <span v-else >Select a option </span> 
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;" v-on:click="models[component.attributeName].attributeValue = option" v-for="(option, ix) in models[component.targetBind].attributeValue" :key="ix"> 
              {{option[Object.keys( option)[0]] }}
            </a>
          </div>
        </div>
        <!-- Range -->
        <br  v-if="component.inputType == 'Range'">
        <span  v-if="component.inputType == 'Range'"> Value: {{ models[component.attributeName].attributeValue }} {{ validationValue(component)}} </span>
        <input  class="" :style="f_styleInpu(index)" v-if="component.inputType == 'Range'"  :min="0" :max="100" type="range"  v-model="models[component.attributeName].attributeValue">

        <!-- Checks -->
        <div class="check-wrap" v-if=" component.inputType == 'CheckBox'">
          <div class="envelop" v-for="(check, zindex) in component['valueOptions']" :key="zindex">
            <input type="checkbox" class="box" :value="check" v-model="models[component.attributeName].attributeValue"/>
            <span class="text" > {{check}} </span>
          </div>
        </div>
        
        <!-- Radio -->
        <div class="check-wrap" v-if=" component.inputType == 'RadioButton'">
          <div class="envelop"  v-for="(check, zindex) in component['valueOptions']" :key="zindex">
            <input type="radio" v-on:click="f_radioSingle(component.itemModel, index)" class="check input" :value="check" v-model="models[component.attributeName].attributeValue"/> 
            <span class="check text" > {{check}} </span>
          </div>
        </div>
        
        <!--div-- class="check" v-if=" component.inputType == 'Boolean' ">
          <div class="envelop" style="width: 100%;" >
            <input type="radio" :value="true"  v-on:click="f_radioSingle(component.itemModel, index)" class="box" v-model="models[component.attributeName].attributeValue"/> 
            <span class="text" > {{component.label}} </span>
          </div>
        </-div-->

        <div class="check-wrap" v-if=" component.inputType == 'Boolean' ">
          <div class="envelop" style="width: 100%;" >
            <input type="checkbox"  class="box box-boolean" v-model="models[component.attributeName].attributeValue"/>
            <span class="text" > {{component.label}} </span>
          </div>
        </div>
        
        <!-- ColorLess -->
        <div style="display: flex;" v-if="component.inputType == 'ColorLess'">
          <div :style="'width: 100%; height: 50px; background:'+color+';'" v-for="(color, index) in previewColors(models[component.attributeName].attributeValue)" :key="index">
            &nbsp; 
          </div>
        </div>
        <textarea class="" contenteditable="true" :style="f_styleInpu(index)" v-on:keyup="auto_grow('textArea-'+component.id)" v-on:click="auto_grow('textArea-'+component.id)" :id="'textArea-'+component.id"  v-if="component.inputType == 'ColorLess'" v-model="models[component.attributeName].attributeValue" type="text" cols=""></textarea>
        <!-- TEXT AREA -->
        <textarea class="" contenteditable="true" :style="f_styleInpu(index)" v-on:keyup="auto_grow('textArea-'+component.id)" v-on:click="auto_grow('textArea-'+component.id)" :id="'textArea-'+component.id"  v-if="component.inputType == 'TextArea'" v-model="models[component.attributeName].attributeValue" type="text" cols=""></textarea>
        <!-- JSON -->
        <textarea class="" contenteditable="true" :style="f_styleInpu(index)" v-on:keyup="auto_grow('textArea-'+component.id)" v-on:click="auto_grow('textArea-'+component.id)" :id="'textArea-'+component.id" v-if="component.inputType == 'JSON'" v-model="models[component.attributeName].attributeValue" type="text" cols=""></textarea>
        <!-- HTML -->
        <div v-if="component.inputType == 'HTML'" class="input-wrap html">
          {{actionPlEditor('pl-editor-'+component.id, component.attributeName, 'set')}}
          <pl-editor :name="component.id" >
            <template #save>
              <span class="editor-btn icon " data-action="save" title="Save" :id="'pl-save-'+component.id" v-on:click="actionPlEditor('pl-editor-'+component.id, component.attributeName, 'save')" >
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#2b2b2b" d="M21 7v12q0 .825-.587 1.413Q19.825 21 19 21H5q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h12Zm-9 11q1.25 0 2.125-.875T15 15q0-1.25-.875-2.125T12 12q-1.25 0-2.125.875T9 15q0 1.25.875 2.125T12 18Zm-6-8h9V6H6Z"/></svg>
              </span>
            </template>
            <template #html >
              <div class="visuell-view" :id="'pl-editor-'+component.id" v-on:keyup="actionPlEditor('pl-editor-'+component.id, component.attributeName, 'check')" contenteditable>
              </div> 
            </template>
            <template #bind>
              <textarea class="html-view"  spellcheck="false" v-model="models[component.attributeName].attributeValue"></textarea>
            </template>
          </pl-editor>
        </div>
        <!-- Editor -->
        <span v-if="component.inputType == 'Editor'" style="font-size:0; position: absolute;"> {{setEditor(component, index)}}</span>
        <div :id="'editor'+index"  v-if="component.inputType == 'Editor'" v-on:keyup="setDataEditor(component, index)"></div>
        
        <!-- File -->
        <div v-if="component.inputType == 'File' " class="smart-wrap">
          <ui-table>
            <template #head>
              <th> No </th>
              <th> Created At</th>
              <th v-if="edit == false"> Description </th>
              <th v-for="(column, indexcolumns) in w_fileColumns" :key="indexcolumns" v-on:click="sortTable(indexcolumns)">
                {{column.label}}
              </th>
              <!--th-- v-if="edit == false"> Thumbnail </!--th-->
              <th v-if="edit == false"> Security </th>
            </template>
            <template #tbody>
              <draggable
                :style="'width: 100%; '"
                v-bind="dragOptions"
                class="dragArea"
                :list="models[component.attributeName].attributeValue"
                group="files"
                @change="log"
                item-key="id" 
                tag="tbody"
              > 
                <template #item="{ element }">
                    <tr >
                      <td 
                        style="cursor: pointer" 
                        data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                        v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element);"
                      > {{models[component.attributeName].attributeValue.indexOf(element)+1}} </td>
                      <td 
                        style="text-align: center; cursor: pointer" 
                        data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                        v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                      >{{formatData('date',element.createdAt)[0]}} <br> {{formatData('date',element.createdAt)[1]}}</td>
                      
                      <td 
                        style="text-align: center; cursor: pointer" 
                        data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                        v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                      >{{element.description}} </td>
                      <td 
                        style=" cursor: pointer" 
                        data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                        v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                      >
                      <span class="absoluteCenterFlex" style="    justify-content: space-between;">
                        {{element.name}}
                        <svg v-if=" element.key.split('.')[1] == 'png' || element.key.split('.')[1] == 'jpg' || element.key.split('.')[1] == 'jpeg'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: rgba(80, 80, 80, 1);transform: ;msFilter:;"><path d="M19.999 4h-16c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-13.5 3a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5.5 10h-7l4-5 1.5 2 3-4 5.5 7h-7z"></path></svg>
                        <svg v-if="element.key.split('.')[1] == 'pdf'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" style="fill: rgba(121, 121, 121, 1);transform: ;msFilter:;"><path d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z"></path><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z"></path></svg>
                        <svg v-if="element.key.split('.')[1] == 'mp4'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" style="fill: rgba(121, 121, 121, 1);transform: ;msFilter:;"><path d="M4 8H2v12a2 2 0 0 0 2 2h12v-2H4z"></path><path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-9 12V6l7 4z"></path></svg>
                      </span>
                    </td>
                    <td 
                        style=" cursor: pointer" 
                        data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                        v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                      >{{element.security}} </td>

                    </tr>
                  
                </template>
              </draggable>
            </template>
            <template  #footer>
              <span v-if="component.readOnly != true && ( models[component.attributeName] && models[component.attributeName].complete != true)" class="add-table" v-on:click="styleModal('file-'+component.id)"  data-toggle="modal" :data-target="'#modal-file-'+component.id">
                Add File 
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="#5a5a5a" stroke-width="2"><path stroke-linecap="round" d="M12 8v4m0 0v4m0-4h4m-4 0H8"/><circle cx="12" cy="12" r="10"/></g></svg> 
              </span>
            </template>
          </ui-table>
          <ui-modal :name="'file-'+component.id" type="file" >
            <template  #content>
              <div class="wrap-head">
                <div class="row">Z
                  <div class="col wrap-title">
                    <h4 class="title-g">
                      {{component.label}}
                    </h4>
                  </div>
                  <div class="col wrap-button">
                    <ui-button :color="'gray'" :text="'X'" :position="'right'" id="close-inner-modal" v-on:click="closeModal('modal-file-'+component.id)" />
                  </div>
                </div>
              </div>
              <div class="smart-wrap">
                <div class="input-wrap col-md-12">
                  <select  name="" id="" v-model="securityFile" v-if="component.securityLevel == 'open'">
                    <option value="public">Public</option>
                    <option value="protected">Protected</option>
                    <option value="private">Private</option>
                  </select>
                </div>
                <div class="input-wrap col-md-12">
                  <label >Description</label>
                  <textarea name="" id="descriptionFile" cols="10" rows="4" v-model="descriptionFile"></textarea>
                </div>
                <div class="input-wrap col-md-12">
                  <input v-on:change="fileUp" type="file" multiple name="" :id="index" ref="refFile"  v-if="edit == false" >
                </div>
              </div>
            </template>
          </ui-modal>
          <ui-modal :name="'file-'+component.id+'-'+aaindex" type="file" v-for="(fileItem, aaindex) in models[component.attributeName].attributeValue" :key="aaindex">
            <template  #content>
              <div class="wrap-head" style="text-align:center;">
                <div class="smart-wrap" v-if="component.readOnly != true && ( models[component.attributeName] && models[component.attributeName].complete != true)">
                  <ui-button v-if="userPermissions.includes('Superadmin') || userPermissions.includes('Admin') || userPermissions.includes('Editor')" class="absoluteCenterFlex" text="" :position="'right'" :style="'width:calc(25% - 10px); margin:5px; background:#fc182b;'" v-on:click="fileDelete(fileItem , index, aaindex );closeModal('modal-file-'+component.id)" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="white" d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z"/></svg>
                  </ui-button>
                  <ui-button class="absoluteCenterFlex" text="" :position="'right'" :style="'width:calc(25% - 10px); margin:5px; background:'+ this.organizationData.colorSeriesA[1]+';'" download  :href="fileItem.url"  target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M19 9h-4V3H9v6H5l7 8zM4 19h16v2H4z"/></svg>
                  </ui-button>
                  <ui-button class="absoluteCenterFlex" text="" :position="'right'" :style="'width:calc(25% - 10px); margin:5px; background:'+ this.organizationData.colorSeriesA[2]+';'" :href="fileItem.url" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><g fill="white"><path d="M11 3a1 1 0 1 0 0 2h2.586l-6.293 6.293a1 1 0 1 0 1.414 1.414L15 6.414V9a1 1 0 1 0 2 0V4a1 1 0 0 0-1-1h-5Z"/><path d="M5 5a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-3a1 1 0 1 0-2 0v3H5V7h3a1 1 0 0 0 0-2H5Z"/></g></svg>
                  </ui-button>
                  <ui-button class="absoluteCenterFlex" :color="'green'" text="" :position="'right'" :style="'width:calc(25% - 10px); margin:5px;'" v-on:click="closeModal('modal-file-'+component.id+'-'+aaindex);saveForce()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 28 28"><path fill="white" d="M8 3H6.25A3.25 3.25 0 0 0 3 6.25v15.5a3.25 3.25 0 0 0 3 3.24v-8.74A2.25 2.25 0 0 1 8.25 14h11.5A2.25 2.25 0 0 1 22 16.25v8.74a3.25 3.25 0 0 0 3-3.24V8.786a3.25 3.25 0 0 0-.952-2.299l-2.535-2.535A3.25 3.25 0 0 0 19.214 3H19v5.75A2.25 2.25 0 0 1 16.75 11h-6.5A2.25 2.25 0 0 1 8 8.75V3Zm9.5 0h-8v5.75c0 .414.336.75.75.75h6.5a.75.75 0 0 0 .75-.75V3Zm3 22v-8.75a.75.75 0 0 0-.75-.75H8.25a.75.75 0 0 0-.75.75V25h13Z"/></svg>
                  </ui-button>
                </div>
                <div class="smart-wrap" v-else>
                  <ui-button :color="'gray'" :text="'X'" :position="'right'" id="close-inner-modal" v-on:click="closeModal('modal-file-'+component.id+'-'+aaindex);" />
                </div>
                <br>
                <h4 class="title-g">
                  {{fileItem.name}}
                </h4>
                <div class="input-wrap" style="margin:10px 0 0 0;">
                  <label v-if="component.readOnly != true && ( models[component.attributeName] && models[component.attributeName].complete != true)" for="">Description</label>
                  <input v-if="component.readOnly != true && ( models[component.attributeName] && models[component.attributeName].complete != true)" type="text" v-model="models[component.attributeName].attributeValue[aaindex].description">
                  <strong v-else> <ui-recursive-display :vdata=" models[component.attributeName].attributeValue[aaindex].description" format="text" :id="component.id" :name="component.id"/> </strong>
                </div>
              </div>
              <div class="smart-wrap" style="margin: 20px 0 0 0;">
                <img style="width: 100%;" v-if=" fileItem.key.split('.')[1] == 'png' || fileItem.key.split('.')[1] == 'jpg' || fileItem.key.split('.')[1] == 'jpeg'" :src="fileItem.url" alt="">
                <video v-else-if="fileItem.key.split('.')[1] == 'mp4'" style="width: 100%;" controls>
                  <source :src="fileItem.url" type="video/mp4">
                </video>
                <embed style="width: 100%; min-height: 500px;"  v-else :src="fileItem.url" type="application/pdf">
              </div>
            </template>
          </ui-modal>
        </div>

        <!-- GRAPHQL -->
        <div class="row form-row inputList" v-if="component.inputType == 'GRAPHQL' || component.inputType == 'GRAPHQL2'">
          <div class="col-md-12">
            <div>
              <a class="dropdown-toggle inputMimo" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span  v-if=" component.isRepeatable == false && models[component.attributeName].attributeValue.value && models[component.attributeName].attributeValue.value != ''" type="text"  disabled style="cursor:default;">
                  {{ models[component.attributeName].attributeValue.value}}
                </span>
                <span  v-else-if=" component.isRepeatable == true && component.itemTable" type="text" disabled style="cursor:default;">
                  {{ component.itemTable.value }}
                </span>
                <span  v-else-if=" component.isRepeatable == true && !component.itemTable" type="text"  disabled style="cursor:default;">
                  {{component.itemTable}}
                </span>
                <span v-else>
                  Select an Option
                </span>
              </a>
              <div  class="dropdown-menu" :id="'dropdown'+component.id" aria-labelledby="dropdownMenuLink">
                <input type="text" :id="'graphqlSearch-'+component.id" v-on:keyup="f_searchSelect(component.id)" v-model="searchSelect">
                <span v-if="component.allowCreate && component.allowCreate == true" v-on:click="createRecordByGraphQl(component, 'graphqlSearch-'+component.id, index, option,component,null)" style="cursor: pointer; display: block;position: absolute;top: 0;font-size: 32px;padding: 4px 10px;color: #838383;right: 0;">+</span>
                <a :style="unSetValueSelect(component, option)" class="dropdown-item" v-on:click="setValueSelect(index, option,component )" v-for="(option, vindex) in component.valueOptions" :key="vindex">{{option.value}}</a>
              </div>
            </div>
          </div>
        </div>
        <!-- PreloadList -->
        <div class="inputList " style="overflow: hidden;" v-if="component.inputType == 'preloadList'">
          <a style="padding: 10px;display: block;" class="inputMimo"  href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{models[component.attributeName].attributeValue.value}}
          </a>
          <div  class="dropdown-menu" :id="'dropdown'+component.id" aria-labelledby="dropdownMenuLink">
            <input type="text" v-on:keyup="f_searchSelect(component.id )" v-model="searchSelect">
            <a :style="unSetValueSelect(component, option)" class="dropdown-item" v-on:click="setValueSelect(index, option,component )" v-for="(option, vindex) in preloadLists[component.metaJSON.shortEntity]" :key="vindex">{{option.value}}</a>
          </div>
        </div>
        
        <div v-if="component.inputType == 'structureMaker'" style="width:100%;">
          <div class="row">
            <div class="col-md-6">
              <paper-displayer :value="models[component.attributeName]"/>
              <!--draggable-- v-bind="dragOptions" id="listSmart" class="dragArea list-group fronter smart-wrap" style="width: 100%;" :list="preloadLists[component.metaJSON.shortEntity]" :group="{ name: 'data', pull: 'clone', put: false }" :clone="cloneInputs" @change="log"  item-key="no" >
                <template #item="{ element }">
                  <div class="list-group-item" :title="'PK:'+element.PK+'\nSK:'+element.SK" style="width: 100%;">
                    {{ element.attributeName }}
                  </div>
                </template>
              </!--draggable-->
            </div>
            <div class="col-md-6">
              <draggable
                :style="'width: 100%; border: 1px dashed rgb(204, 204, 204); box-sizing: border-box;'"
                v-bind="dragOptions "
                class="dragArea smart-wrap"
                :list="models[component.attributeName].attributeValue"
                group="data"
                item-key="id"
              > 
              </draggable>
            </div>
          </div>
        </div>

        <!-- Quote --->
        <div class="row quoteComponent" v-if="component.inputType == 'quote'" style="display:block;">
          <div class="col-md-8 row" style="float:left;">
            <div class="element-wrap col-md-12" style="overflow-y: auto;" v-if="false" > 
              <table class="table table-repeatable" id="myTable" style="min-width: 100%" >  
                <tbody>
                  <tr class="input-wrap "   v-for="(item, indexItem) in models[component.attributeName].attributeValue.data" :key="indexItem">
                    <td  v-for="(column, indextd) in setHeaders(index)" :key="indextd">
                      <div  v-if="component.readOnly == true">
                        {{models[component.attributeName].attributeValue[indexItem][column.attributeName]}}
                      </div>
                      <div v-else>
                        <span :style="'width:100%; float: left;'"  v-for="(zitem, zindex) in component.metaJSON.attributes" :key="zindex"> 
                          <span v-if="zindex == 0 || zindex == 1 ">
                            <label >{{zitem.label}}</label>
                            <input v-on:change="f_totals(component)" v-if="zitem.attributeType == 'Text' || zitem.attributeType == 'Currency' || zitem.attributeType == 'number'" min=0 class=" " :type="zitem.attributeType" style="height: 40px;" v-model=" models[component.attributeName].attributeValue.data[indexItem][zitem.attributeName]">
                            <span  v-if="zitem.attributeType == 'TextArea'" style="font-size:0; position: absolute;"> {{setEditor(component, index, indexItem )}}</span>
                            <div :id="'editor'+index+'-'+indexItem"  v-if="zitem.attributeType == 'TextArea'" v-on:keyup="setDataEditor(component, index, indexItem)"></div>
                          </span>
                        </span>
                      </div>
                    </td>
                    <td class="options-td" style="vertical-align: top; width: 110px; position:relative;"  v-for="(column, indextd) in setHeaders(index)" :key="indextd">
                      <div  v-if="component.readOnly == true">
                        {{models[component.attributeName].attributeValue[indexItem][column.attributeName]}}
                      </div>
                      <div v-else >
                        <span :style="'width:100%; float: left;'"  v-for="(zitem, zindex) in component.metaJSON.attributes" :key="zindex"> 
                          <span v-if="zindex == 2 || zindex == 3 ">
                            <label >{{zitem.label}}</label>
                            <input v-on:keyup="f_totals(component)" v-if="zitem.attributeType == 'Text' || zitem.attributeType == 'Currency' || zitem.attributeType == 'number'" min=0 class=" " :type="zitem.attributeType" style="height: 40px;" v-model=" models[component.attributeName].attributeValue.data[indexItem][zitem.attributeName]">
                            <textarea v-if="zitem.attributeType == 'TextArea'" name="" id="" cols="20" rows="5" class=" "  v-model="models[component.attributeName].attributeValue.data[indexItem][zitem.attributeName]"></textarea>
                          </span>
                        </span>
                        <div class="options" style=" width:auto; position:absolute;bottom: 10px; display: block; right: 10px;">
                          <a style="cursor: pointer" v-on:click="f_repeatableAction(index, 'delete', component, indexItem)" >
                            <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                              <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                                <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="element-wrap col-md-12 list-group" v-else > 
              <div class="list-group-item list-group-item-action" style="padding: 30px 10px 10px 10px;" v-for="(item, indexItemQuote) in models[component.attributeName].attributeValue.data" :key="indexItemQuote" >
                <div class="smart-wrap">
                  <div class="input-wrap col-12">
                    <label for="">Name</label>
                    <input type="text" v-model="models[component.attributeName].attributeValue.data[indexItemQuote]['product.name']">
                  </div>
                  <div class="options" style=" width:auto; position:absolute;top: 10px; display: block; right: 10px;">
                    <a style="cursor: pointer" v-on:click="f_repeatableAction(index, 'delete', component, indexItemQuote)" >
                      <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                        <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                          <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                        </svg>
                      </div>
                    </a>
                  </div>
                  <div class="input-wrap col-6">
                    <label for="">Price</label>
                    <input type="text"  v-on:change="f_totals(component)" v-model="models[component.attributeName].attributeValue.data[indexItemQuote]['product.price']">
                  </div>
                  <div class="input-wrap col-6">
                    <label for="">Discount</label>
                    <input type="text"  v-on:change="f_totals(component)" v-model="models[component.attributeName].attributeValue.data[indexItemQuote]['product.discount']">
                  </div>
                  <div class="input-wrap col-12">
                    <label for="">Description</label>
                    <span style="font-size:0; position: absolute;"> {{setEditor(component, index, indexItemQuote )}}</span>
                    <div :id="'editor'+index+'-'+indexItemQuote"   v-on:keyup="setDataEditor(component, index, indexItemQuote)"></div>
                  </div>
                </div>
              </div>     
            </div>
            <br>
            <div v-if="models[component.attributeName].attributeValue.totals != undefined" class="element-wrap col-12 " style="padding:10px 0;">
              <table class="table table-repeatable" id="myTable" style="min-width: 100%" >  
                <tbody>
                  <tr>
                    <td class="row">
                      <span class="col-6" style="color: #787878; font-size: 16px; font-weight: 600;" >Sub Total</span> <span class="col-6" style="text-align:right; font-size: 16px;  " >$ {{models[component.attributeName].attributeValue.totals.subTotal}}</span>
                      <span class="col-6" style="color: #787878; font-size: 16px; font-weight: 600;" >Discount</span> <span class="col-6" style="text-align:right; font-size: 16px;  " >$ {{models[component.attributeName].attributeValue.totals.discount}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="row">
                      <span class="col-6" style="color: #787878; font-size: 16px; font-weight: 700;" >Total</span> <span class="col-6" style="text-align:right; font-weight: 600; font-size: 16px;  " >$ {{models[component.attributeName].attributeValue.totals.total}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="element-wrap col-12" style="padding:10px 0;" >
              <h6 style="padding:10px 0;" >Payment Plan</h6>
              <table class="table table-repeatable" id="myTable" style="min-width: 100%" >  
                <thead>
                  <th>No.</th>
                  <th>Days</th>
                  <th>Amount</th>
                  <th>Notes</th>
                  <th>Options</th>
                </thead>
                <tbody>
                  <tr class="input-wrap" v-for="(yitem, yindex) in models[component.attributeName].attributeValue.steps" :key="yindex">
                    <td>{{yindex+1}}</td>
                    <td><input type="number" name="" id="" v-model="yitem.days"></td>
                    <td><input type="number" name="" id="" v-model="yitem.amount"></td>
                    <td><input type="text" name="" id="" v-model="yitem.notes"></td>
                    <td class="options-td" style="padding: 0px !important; width:auto; ">
                      <div class="options" style="width: auto;!important">
                        <a style="cursor: pointer" v-on:click="f_repeatableAction(index, 'deleteSteps', component, yindex)" >
                          <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                            <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                              <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="models[component.attributeName].attributeValue.totals != undefined && models[component.attributeName].attributeValue.steps != undefined">
                    <td></td> 
                    <td>TOTAL</td>
                    <td></td>
                    <td style="text-align:right ; color: #fc182b; font-weight: bold; font-size: 16px;" v-if="totalQuote(models[component.attributeName].attributeValue) > models[component.attributeName].attributeValue.totals.total"> {{ totalQuote(models[component.attributeName].attributeValue)}}</td>
                    <td style="text-align:right ; color: #65A603; font-weight: bold; font-size: 16px;" v-if="totalQuote(models[component.attributeName].attributeValue) == models[component.attributeName].attributeValue.totals.total"> {{ totalQuote(models[component.attributeName].attributeValue)}}</td>
                    <td style="text-align:right ; color: #005DC7; font-weight: bold; font-size: 16px;" v-if="totalQuote(models[component.attributeName].attributeValue) < models[component.attributeName].attributeValue.totals.total"> {{ totalQuote(models[component.attributeName].attributeValue)}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div class="btn btn-repeatable"  v-on:click="f_repeatableAction(index, 'pushSteps' , component)" >
                <i class='bx bx-plus' ></i>
              </div>
            </div>
          </div> 
          <div class="col-md-4" style="padding:0 10px; float:left;" v-if="widthScreen > 767">
            <input type="search" :id="'search-'+component.id" v-on:keyup="searchQuote(component)" > 
              <br>
              <br>
            <div class="list-group" :id="'list-'+component.id">
              <div  class="list-group-item list-group-item-action" v-for="(yitem, yindex) in products" :key="yindex" v-on:click="f_repeatableAction(index, 'pushQuote' , component, yindex)" >
                <a  style="width: 25px; height: 25px; display: inline-block; position: relative; float: right; cursor:pointer;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" style="fill: rgba(113, 113, 113, 1);transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
                </a>
              
                <span style=" width: calc(100% - 30px);  float:left;">{{yitem['product.name']}}  </span>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="padding:0 10px; float:left;" v-else>
            <div class="input-wrap col-md-12" >
               <label for="">Search Product</label>
               <input type="text" :id="'search-'+component.id" v-on:keyup="searchQuote(component)"  >
               <i class="bx bx-search clearSearch" data-toggle="collapse" href="#contactList" role="button" aria-expanded="false" aria-controls="contactList"  ></i>
               <div class="collapse" id="contactList">

                <div class="list-group" :id="'list-'+component.id">
                  <div  class="list-group-item list-group-item-action" v-for="(yitem, yindex) in products" :key="yindex" v-on:click="f_repeatableAction(index, 'pushQuote' , component, yindex)" data-toggle="collapse" href="#contactList" role="button" aria-expanded="false" aria-controls="contactList">
                    <a  style="width: 25px; height: 25px; display: inline-block; position: relative; float: right; cursor:pointer;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" style="fill: rgba(113, 113, 113, 1);transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
                    </a>
                  
                    <span style=" width: calc(100% - 30px);  float:left;">{{yitem['product.name']}}  </span>
                  </div>
                </div>

               </div>
            </div>
            
          </div>
        </div>
        <!-- Calendar -->
        <div v-if="component.inputType == 'calendar' && $router.currentRoute.value.path != '/dashboard' " class="row calendar-wrap">
          <div class="col-calendar">
            <div class="wrap-header">
              <div class="pre" v-if="mes>1" v-on:click="actionCalendar('back', component.id, models['date'].attributeValue); mes--">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#5a5a5a" fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm2.207 7.707a1 1 0 0 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L10.914 12l3.293-3.293Z" clip-rule="evenodd"/></svg>
              </div>
              <div class="info">
                  <div class="head-month" :id="'month-'+component.id"></div>
                  <div class="head-day" :id="'day-'+component.id"></div>
              </div>
              <div class="next" v-on:click="actionCalendar('next', component.id, models['date'].attributeValue);mes++">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g transform="translate(24 0) scale(-1 1)"><path fill="#5a5a5a" fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm2.207 7.707a1 1 0 0 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L10.914 12l3.293-3.293Z" clip-rule="evenodd"/></g></svg>
              </div>
            </div>
            <table :id="'calendar-'+component.id" class="preview-calendar" >
              <thead>
                <tr>
                  <th>S</th>
                  <th>M</th>
                  <th>T</th>
                  <th>W</th>
                  <th>T</th>
                  <th>F</th>
                  <th>S</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(week, windex) in 6" :key="windex" > 
                  <td v-for="(day, dindex) in 7" :key="dindex" :id="'days-'+component.id" @click="(event) => {models['date'].attributeValue = event.target.title; actionCalendar('range', dindex, event.target.title); actionCalendar('init', component.id, new Date(event.target.title) )}">
                      <a>{{day}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="!models['date'].attributeValue" style="display:none;">
              {{models['duration'].attributeValue = webSite.duration}}
              {{models['date'].attributeValue = new Date().toISOString().split('T')[0] }}
              {{actionCalendar('range', new Date().getDay(), new Date()) }}
              {{actionCalendar('init', component.id, new Date() )}}
            </span>
          </div>
          <div class="col-content" style="padding:0 10px;" v-if="models['date'].attributeValue"> 
            <h4>{{ new Date(models['date'].attributeValue).toUTCString().slice(0,16)}} </h4>
            <h5 style="text-align:center;">{{webSite.timeZone}}</h5>
            <div :class="{ 'meet':true, 'active': meet == models['time'].attributeValue }"  v-for="(meet, mindex) in calendarHours" :key="mindex">  
              <a v-if="webSite.bookings[models['date'].attributeValue]" :class="{'disabled': webSite.bookings[models['date'].attributeValue].hasOwnProperty(meet) && !( webSite.bookings[models['date'].attributeValue][meet] < parseInt(webSite.calAtt.maxSessions.attributeValue)) }" style="color:#5a5a5a;"  data-toggle="collapse" :href="'#collapse-'+meet" role="button" aria-expanded="false" :aria-controls="'#collapse-'+meet">

                {{actionCalendar('display',meet)}}
              </a>
              <a v-else style="color:#5a5a5a;"  data-toggle="collapse" :href="'#collapse-'+meet" role="button" aria-expanded="false" :aria-controls="'#collapse-'+meet">

                {{actionCalendar('display',meet)}}
              </a>
              <div class="collapse" :id="'collapse-'+meet">
                <div class=" smart-wrap">
                  <div class="input-wrap col-6">
                    <label for="" >First Name</label>
                    <input type="text" v-model="models['contact.firstName'].attributeValue">
                  </div>
                  <div class="input-wrap col-6">
                    <label for="">Last Name</label>
                    <input type="text" v-model="models['contact.lastName'].attributeValue">
                  </div>
                  <div class="input-wrap col-6">
                    <label for="">Email</label>
                    <input type="email" v-model="models['email'].attributeValue">
                  </div>
                  <div class="input-wrap col-6">
                    <label for="">Phone Number</label>
                    <input type="text" v-on:keyup="(event) =>{inputPhone('phoneNumber','n', event)}" v-model="phone['phoneNumber']"> 
                  </div>
                  <div class="input-wrap col-12">
                    <label for="">Description</label>
                    <textarea name="" id="" cols="30" rows="4" v-model="models['description'].attributeValue"></textarea>

                  </div>
                </div>
                <div class="smart-wrap">
                  <ui-button text="Cancel" color="red" :style="'background:'+organizationData.colorSeriesA[0]+'; margin:10px 10px; width: calc( 50% - 20px ); font-weight: 500; padding: 10px;line-height: 20px!important;font-size: 14px;height: 40px;'" data-toggle="collapse" :href="'#collapse-'+meet" role="button" aria-expanded="false" :aria-controls="'#collapse-'+meet" v-on:click="models['time'].attributeValue = '' " />
                  <ui-button text="Confirm" color="green" :style="'background:'+organizationData.colorSeriesA[1]+'; margin: 10px 10px; width: calc( 50% - 20px ); font-weight: 500; padding: 10px;line-height: 20px!important;font-size: 14px;height: 40px;'" v-on:click="models['time'].attributeValue = meet; actionButton(webSite,'updateCalendar')"/>
                </div>
              </div>   
            </div>
          </div>
        </div>

        <!-- Computed -->
        <div v-if="component.inputType == 'Computed'">
          <span v-if="component.formula && component.format" style="background: #D9D9D9; color: #5A5A5A; width: 100%; min-height: 45px;  display: block; padding: 10px;" v-on:click="setElementComputed(component)"  >
            <span v-if="component.format == 'currency'" >{{ (models[component.attributeName].attributeValue).toLocaleString('en-US', { style: 'currency', currency: 'USD'}) }}</span>
            <span v-else >{{ models[component.attributeName].attributeValue}}</span>
          </span>
          <span v-else style="background: #D9D9D9; color: #5A5A5A; width: 100%; min-height: 45px;  display: block; padding: 10px;" >
            No formula found
          </span>
        </div>
      </div> 
      <div  v-if="conditionInput('read',component) && component.metaType == 'metaInput' " style="display:block; font-weight: 700; font-size: 18px;">
        <span v-if="typeof models[component.attributeName].attributeValue == 'object' " >
          <span  v-show="ax != 'PK' && ax != 'SK' && ax != 'id'"   :id="ax" v-for="(item, ax) in models[component.attributeName].attributeValue" :key="ax">
            {{item}}
          </span>
        </span>
        <span v-if="typeof models[component.attributeName].attributeValue != 'object'">
          {{models[component.attributeName].attributeValue}}
        </span>
        <div v-if=" models[component.attributeName]  && models[component.attributeName].attributeValue && component.inputType == 'File'">  
          <ui-table>
            <template #head>
              <th> No </th>
              <th> Created At</th>
              <th v-if="edit == false"> Description </th>
              <th v-for="(column, indexcolumns) in w_fileColumns" :key="indexcolumns" v-on:click="sortTable(indexcolumns)">
                {{column.label}}
              </th>
              <!--th-- v-if="edit == false"> Thumbnail </!--th-->
              <th v-if="edit == false"> Security </th>
            </template>
            <template #tbody>
              <tbody>
                <tr v-for="(element, index) in models[component.attributeName].attributeValue" :key="index" >
                <td 
                  style="cursor: pointer" 
                  data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                  v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element);"
                > {{models[component.attributeName].attributeValue.indexOf(element)+1}} </td>
                <td 
                  style="text-align: center; cursor: pointer" 
                  data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                  v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                >{{formatData('date',element.createdAt)[0]}} <br> {{formatData('date',element.createdAt)[1]}}</td>
                
                <td 
                  style="text-align: center; cursor: pointer" 
                  data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                  v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                >{{element.description}} </td>
                <td 
                  style=" cursor: pointer" 
                  data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                  v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                >
                <span class="absoluteCenterFlex" style="    justify-content: space-between;">
                  {{element.name}}
                  <svg v-if=" element.key.split('.')[1] == 'png' || element.key.split('.')[1] == 'jpg' || element.key.split('.')[1] == 'jpeg'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: rgba(80, 80, 80, 1);transform: ;msFilter:;"><path d="M19.999 4h-16c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-13.5 3a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5.5 10h-7l4-5 1.5 2 3-4 5.5 7h-7z"></path></svg>
                  <svg v-if="element.key.split('.')[1] == 'pdf'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" style="fill: rgba(121, 121, 121, 1);transform: ;msFilter:;"><path d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z"></path><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z"></path></svg>
                  <svg v-if="element.key.split('.')[1] == 'mp4'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" style="fill: rgba(121, 121, 121, 1);transform: ;msFilter:;"><path d="M4 8H2v12a2 2 0 0 0 2 2h12v-2H4z"></path><path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-9 12V6l7 4z"></path></svg>
                </span>
              </td>
              <td 
                  style=" cursor: pointer" 
                  data-toggle="modal" :data-target="'#modal-file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element)" 
                  v-on:click="styleModal('file-'+component.id+'-'+models[component.attributeName].attributeValue.indexOf(element));fileAction('set',component, models[component.attributeName].attributeValue.indexOf(element), element); "
                >{{element.security}} </td>

              </tr>
              </tbody>
            </template>
          </ui-table>
          <ui-modal :name="'file-'+component.id+'-'+aaindex" type="file" v-for="(fileItem, aaindex) in models[component.attributeName].attributeValue" :key="aaindex">
            <template  #content>
              <div class="wrap-head" style="text-align:center;">
                <div class="smart-wrap" >
                  <ui-button :color="'gray'" :text="'X'" :position="'right'" id="close-inner-modal" :style="'width:calc(25% - 10px); margin:5px; background:'+ this.organizationData.colorSeriesA[1]+';'" v-on:click="closeModal('modal-file-'+component.id+'-'+aaindex);" />
                  <ui-button class="absoluteCenterFlex" text="" :position="'right'" :style="'width:calc(25% - 10px); margin:5px; background:'+ this.organizationData.colorSeriesA[1]+';'" download  :href="fileItem.url"  target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M19 9h-4V3H9v6H5l7 8zM4 19h16v2H4z"/></svg>
                  </ui-button>
                  <ui-button class="absoluteCenterFlex" text="" :position="'right'" :style="'width:calc(25% - 10px); margin:5px; background:'+ this.organizationData.colorSeriesA[2]+';'" :href="fileItem.url" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><g fill="white"><path d="M11 3a1 1 0 1 0 0 2h2.586l-6.293 6.293a1 1 0 1 0 1.414 1.414L15 6.414V9a1 1 0 1 0 2 0V4a1 1 0 0 0-1-1h-5Z"/><path d="M5 5a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-3a1 1 0 1 0-2 0v3H5V7h3a1 1 0 0 0 0-2H5Z"/></g></svg>
                  </ui-button>
                </div>
                <br>
                <h4 class="title-g">
                  {{fileItem.name}}
                </h4>
                <div class="input-wrap" style="margin:10px 0 0 0;">
                  <label v-if="component.readOnly != true && ( models[component.attributeName] && models[component.attributeName].complete != true)" for="">Description</label>
                  <input v-if="component.readOnly != true && ( models[component.attributeName] && models[component.attributeName].complete != true)" type="text" v-model="models[component.attributeName].attributeValue[aaindex].description">
                  <strong v-else> <ui-recursive-display :vdata=" models[component.attributeName].attributeValue[aaindex].description" format="text" :id="component.id" :name="component.id"/> </strong>
                </div>
              </div>
              <div class="smart-wrap" style="margin: 20px 0 0 0;">
                <img style="width: 100%;" v-if=" fileItem.key.split('.')[1] == 'png' || fileItem.key.split('.')[1] == 'jpg' || fileItem.key.split('.')[1] == 'jpeg'" :src="fileItem.url" alt="">
                <video v-else-if="fileItem.key.split('.')[1] == 'mp4'" style="width: 100%;" controls>
                  <source :src="fileItem.url" type="video/mp4">
                </video>
                <embed style="width: 100%; min-height: 500px;"  v-else :src="fileItem.url" type="application/pdf">
              </div>
            </template>
          </ui-modal>
        </div> 
      </div>
      <!-- Layout -->
      <div class="row" v-if="component.metaType == 'metaLayout' && component.elementType != 'repeatableCombo' && component.web != true">
        <span style="color: #ccc; font-size: 10px; display:none;" v-if="component.isRepeatable == false && component.parentChildren == undefined && component.elementType != 'repeatableCombo'">{{ models[component.attributeName].attributeValue = setValueLayout(component)}}</span>
        <paper-layout     :palette="palette" :idioms="idioms" :layoutCategory="layoutCategory" :filterSmarForm="filterSmarForm" :record="record" :data="data" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="models" v-if="component.isRepeatable == false && component.parentChildren == undefined "/>
      </div>
      <div class="row" style="display: flex; justify-content: center;" v-if="component.metaType == 'metaLayout' && component.elementType != 'repeatableCombo' && component.web == true" >
        <paper-layout
          :palette="palette" 
          :id="bindex" 
          :idioms="idioms" 
          :layoutCategory="'bind'" 
          :paperPermissions="paperPermissions" 
          :layout="component.smartComponents" 
          :models="{...bitem, responsive: models.responsive, choice: 'test'}" 
          :class="{ 'last-block': bindex = models[component.attributeName].length }" 
          :style="setStyle(component, 'component')" 
          v-for="(bitem, bindex) in models[component.attributeName]" :key="bindex"
        />
      </div>
      <div class="row " v-if="component.elementType == 'repeatableCombo' && models[component.attributeName]" >
        <paper-layout     :palette="palette" :idioms="idioms" :layoutCategory="layoutCategory" :filterSmarForm="filterSmarForm" :record="record" :class="component.class" v-on:click="updateRecord(pitem, 'page')" :style="component.styleWrap" :data="pitem" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="models[component.attributeName].data[pindex]" v-for="(pitem, pindex) in models[component.attributeName].data" :key="pindex" />
      </div> 
      <div class="row " v-if="component.elementType == 'repeatableCombo' && models[component.attributeName] == [] " > 
        <p style="padding: 20px;border: 1px dashed rgb(204, 204, 204);width: 500px;display: block;margin: 20px auto;text-align: center;color: #5f5f5f;font-weight: 600;"> 
          Layout bind not found </p> 
      </div>

      <!-- Element  -->
      <div v-if="component.metaType == 'metaElement'">
        <!--span-- v-if="layoutCategory == 'bind'">
          {{component.attributeName}}
          <paper-displayer :value="models"/>
        </!--span-->
        <div class="row" v-if="component.elementType == 'Collapse'">
          <h6 style="width:100%;" data-toggle="collapse" :href="'#collapse-'+component.no" role="button" aria-expanded="false" aria-controls="collapseExample" :style="setStyle(component, 'component')">
            {{component.text[gLanguage]}}
          </h6>
          <div style="width:100%;" class="collapse" :id="'collapse-'+component.no">
            <paper-layout     :palette="palette" :idioms="idioms"   :layoutCategory="layoutCategory" :filterSmarForm="filterSmarForm" :data="data" :record="record" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="models" />
          </div>
        </div>
        <!--Dropdown-->
        <div v-if="component.elementType == 'dropdown'" class="dropdown">
          <p :style="setStyle(component, 'component')"  class="dropdown-toggle" href="#" role="button" :id="component.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{setValueFront(component, models)}}
          </p>
          <paper-layout    class="dropdown-menu fadeIn" :aria-labelledby="component.id"  :palette="palette" :idioms="idioms"  :layoutCategory="layoutCategory" :filterSmarForm="filterSmarForm" :data="data" :paperPermissions="paperPermissions" :record="record" :layout="component.smartComponents" :models="models" :style="setStyle(component, 'component')" />
        </div>
        <!--gLanguage-->
        <div v-if="component.elementType == 'language'" class="gLanguage"> 
          <span v-for="(idiom, yindex) in idioms" :key="yindex" :style="setStyle(component, 'component')" :class="{ 'active': idiom == gLanguage, 'line': yindex+1 != idioms.length } " v-on:click="setLanguage(idiom)">{{srcIdioms[idiom]}}</span>
        </div>  
        <!--amplify-s3-image :img-key="setValueFront(component, models)" v-if="component.elementType == 'Img'"  :style="setStyle(component, 'component')"/-->
        <img v-if="component.elementType.toLowerCase() == 'img'" :style="setStyle(component, 'component')" :src="setValueFront(component, models)"  data-toggle="modal" :data-target="'#modal-imgGallery-'+models.SK"  alt="not found" srcset="">
        <ui-modal v-if="component.elementType.toLowerCase() == 'img' && (component.showModal && component.showModal == true)" :name="'imgGallery-'+models.SK" :type="component.sizeModal"  >
          <template #content>
            <ui-button style=" position: absolute; right: 40px;" text="x" data-dismiss="modal" />
            <img style="width:100%;" :src="setValueFront(component, models)" :alt="setValueFront(component, models)" srcset="">
          </template>
        </ui-modal>
        

        <!--iframe-->
        <iframe v-if="component.elementType == 'iframe'" :src="component.url" frameborder="0" :style="setStyle(component, 'component')"></iframe>

        <video v-if="component.elementType.toLowerCase() == 'video'"  :style="setStyle(component, 'component')" >
          <source :src="setValueFront(component, models)" type="video/webm">
          <source :src="setValueFront(component, models)" type="video/mp4">
        </video>
        
        <p v-if="component.elementType == 'Text' && component.class != 'dateFormat' "  :id="id+'-'+index+'-p'" :style="setStyle(component, 'component')"> {{setValueFront(component, models, id+'-'+index)}}</p>
        <div v-if="component.elementType == 'Text' && component.class != 'dateFormat' " :id="id+'-'+index" :style="setStyle(component, 'component')"></div>
        <p v-if="component.elementType == 'Text' && component.class == 'dateFormat' " :style="setStyle(component, 'component')">{{ setDate(setValueFront(component, models))[0] }} <br>{{setDate(setValueFront(component, models))[1]}}</p>
        <h1 v-if="component.elementType == 'Title' && component.titleType == 'h1'" :style="setStyle(component, 'component')">{{setValueFront(component, models)}}</h1>
        <h2 v-if="component.elementType == 'Title' && component.titleType == 'h2'" :style="setStyle(component, 'component')">{{setValueFront(component, models)}}</h2>
        <h3 v-if="component.elementType == 'Title' && component.titleType == 'h3'" :style="setStyle(component, 'component')">{{setValueFront(component, models)}}</h3>
        <h4 v-if="component.elementType == 'Title' && component.titleType == 'h4'" :style="setStyle(component, 'component')">{{setValueFront(component, models)}}</h4>
        <h5 v-if="component.elementType == 'Title' && component.titleType == 'h5'" :style="setStyle(component, 'component')">{{setValueFront(component, models)}}</h5>
        <h6 v-if="component.elementType == 'Title' && component.titleType == 'h6'" :style="setStyle(component, 'component')">{{setValueFront(component, models)}}</h6>

        <a :style="setStyle(component, 'component')+ 'display: flex; align-items: center; justify-content: center;'" :onclick="'actionMenu(\'collapseMenu-'+responsive+'\')'" v-if="component.elementType == 'menu' && ( responsive == '3' || responsive == '2'  ) ">
          <svg v-if="!component.url" width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="4.6" fill="#fff"/>
            <rect y="9.19995" width="25" height="4.6" fill="#fff"/>
            <rect y="18.4" width="25" height="4.6" fill="#fff"/>
          </svg>
          <img style="width: 100%; display: block;" :src="component.url" alt="">
        </a>
        <div v-if="component.elementType == 'menu' && ( responsive == '3' || responsive == '2'  )" class="menuResponsive hide" :id="'collapseMenu-'+responsive">
          <div class="headerMenu">
            <img class="menuLogo" style="margin: 25px;" :src="models['Org.logoColor']" alt="">
            <svg style="margin: 25px;" xmlns="http://www.w3.org/2000/svg" :onclick="'actionMenu(\'collapseMenu-'+responsive+'\')'" width="30" height="30" viewBox="0 0 256 256"><path fill="currentColor" d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z"/></svg>
            
          </div>    
          <paper-layout :palette="palette" :idioms="idioms"  :layoutCategory="layoutCategory" :filterSmarForm="filterSmarForm" :data="data" :record="record" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="models" :style="setStyle(component, 'component')+' width: 100%; align-content: center; height: calc(100vh - 150px);'" />
        </div>
        <!-- Div -->
        <paper-layout 
          v-if="component.elementType == 'Div' && ( component.divType && component.divType == 'choice') " 
          :id="component.attributeName+'-'+responsive" 
          :class="
          { 
            'choice':component.divType && component.divType == 'choice', 
            'selected': choice == models.SK,   
          }" 
          :palette="palette" 
          :idioms="idioms"  
          :layoutCategory="layoutCategory" 
          :filterSmarForm="filterSmarForm" 
          :data="data" 
          :record="record" 
          :paperPermissions="paperPermissions" 
          :layout="component.smartComponents" 
          :models="models" 
          :style="setStyle(component, 'component')+ ' cursor: pointer;'"
          v-on:click="setChoice(models.SK)"
        />
        <paper-layout 
          v-if="component.elementType == 'Div' && (component.divType && component.divType == 'step') " 
          :id="component.attributeName+'-'+responsive" 
          :class="
          { 
            'step':component.divType && component.divType == 'step', 
            'show': models.step == component.attributeName,   
          }" 
          :palette="palette" 
          :idioms="idioms"  
          :layoutCategory="layoutCategory" 
          :filterSmarForm="filterSmarForm" 
          :data="data" 
          :record="record" 
          :paperPermissions="paperPermissions" 
          :layout="component.smartComponents" 
          :models="models" 
          :style="setStyle(component, 'component')+ ' cursor: pointer;'"
        />
        <paper-layout 
          v-if="component.elementType == 'Div' && !component.divType" 
          :id="component.attributeName+'-'+responsive" 
          :palette="palette" 
          :idioms="idioms"  
          :layoutCategory="layoutCategory" 
          :filterSmarForm="filterSmarForm" 
          :data="data" 
          :record="record" 
          :paperPermissions="paperPermissions" 
          :layout="component.smartComponents" 
          :models="models" 
          :style="setStyle(component, 'component')+ ' cursor: pointer;'"
        />
        <!-- Menu -->
        <paper-layout :id="component.attributeName+'-'+responsive"     :palette="palette" :idioms="idioms"  :layoutCategory="layoutCategory" v-if="component.elementType == 'menu' && ( responsive != '3' && responsive != '2')" :filterSmarForm="filterSmarForm" :data="data" :record="record" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="models" :style="setStyle(component, 'component')" />
        <form class="smart-wrap" v-if="component.elementType == 'contactForm'" action="" :id="'contactForm-'+component.attributeName+'-'+responsiveText" :class="component.id"  :style="setStyle(component, 'component')">
          <div :class="'input-wrap '+input.class"  v-for="(input, index) in component.smartComponents" :key="index" :style="setStyle(input, 'wrap')">
            
            <label :style="input.styleLabel" v-if="input.elementType == 'Input'" for="">{{ input.label }} </label>

            <textarea class="input" v-if="input.inputType == 'TextArea' && input.elementType == 'Input'" name="" :id="input.attributeName"  cols="30" rows="10" :style="input.styleInput" v-model="models.form[input.attributeName]"></textarea>
            <select class="input" v-else-if="input.inputType == 'Select' && input.elementType == 'Input'"  :id="input.attributeName" :style="input.styleInput" v-model="models.form[input.attributeName]">
              <option v-for="(option, index) in input.valueOptions" :key="index" :value="option">{{ option }} </option>
            </select>

            <input v-else-if="input.inputType == 'Phone' && input.elementType == 'Input'" :id="input.attributeName" type="text" v-on:keyup="(event) =>{inputPhone(input.attributeName,'form', event)}" v-model="phone[input.attributeName]">

            <input v-else-if="input.inputType == 'Email' && input.elementType == 'Input'" :id="input.attributeName" type="email" v-on:keyup="inputAction( input , 'email' ,'form')" v-model="inputValue[input.attributeName]">

            <input class="input" v-else-if=" input.elementType == 'Input'" :type="input.inputType.toLowerCase()" v-on:keyup="inputAction( input , 'pass' ,'form')" :id="input.attributeName" :style="input.styleInput"  v-model="models.form[input.attributeName]">

            <span style=" color: #ff0000; font-size: 12px; font-weight: 700;" :id="'input-compareTo'+input.attributeName" class= "none" v-if="input.elementType == 'Input'" for="">  {{  input.label }} are not the same </span>

            <ui-button v-if="input.elementType == 'smartButton'" :onclick="`contactForm('${component.attributeName}')`" style="padding:10px 20px;" :color="'gray'" :text="input.text['en']" :position="'center'" :style="setStyle(input, 'component')"/>
          </div>
        </form>
        
        <a class="row" v-if="component.elementType == 'link'" :href="setValueFront(component, models)" :target="component.target" rel="noopener noreferrer" v-on:click="actionLink(component)">
          <paper-layout     :palette="palette" :idioms="idioms"  :layoutCategory="layoutCategory" :filterSmarForm="filterSmarForm" :data="data" :paperPermissions="paperPermissions" :record="record" :layout="component.smartComponents" :models="models" :style="setStyle(component, 'component')" />
        </a>
        <!-- Charts -->
        <ui-charts v-if="component.elementType == 'Charts'" :type="component.typeCharts" :data="models[component.attributeName]" :color="organizationData.colorSeriesA" :component="component" />

        <!-- Buttons -->
        <ui-button data-toggle="modal" :data-target="'#modal-productShop-'+data.SK" :color="component.color" :position="'center'" :text="component.text[gLanguage]" v-if="component.elementType == 'shopButton' && component.shopButtonType == 'add'"  :style="setStyle(component, 'component')"/>
        
        <ui-button data-toggle="modal" data-target="#modal-cart" v-on:click="shopButton('view')" :color="component.color" :position="'center'" :text="component.text[gLanguage]" v-if="component.elementType == 'shopButton' && component.shopButtonType == 'view'"  :style="setStyle(component, 'component')"/>
        
        <ui-button v-on:click="saveButton()" :color="component.color" :position="'center'" :text="component.text[gLanguage]" v-if="component.attributeName == 'Button-Save' "  :style="setStyle(component, 'component')"/>
        <ui-button v-on:click="quizActionButton(component)" :color="component.color" :position="'center'" :text="component.text[gLanguage] " v-if="component.attributeName == 'Button-TitleInsurance' "  :style="setStyle(component, 'component')"/>
        <div v-if="component.attributeName == 'Button-TitleInsurance' " :id="'titleInsurance-'+component.id"></div>

        <ui-button v-on:click="actionButton(component)" class='absoluteCenterFlex' :color="component.color" :text="component.text[gLanguage] " v-if="component.elementType == 'smartButton' && component.buttonType != 'appointment' && component.buttonType != 'contactForm' "  :style="setStyle(component, 'component')">
          &nbsp;<svg v-if="component.buttonType == 'appointment'" xmlns="http://www.w3.org/2000/svg" width="30" height="50" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="white"><path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zm5.402 9.746c.625 0 1.184-.484 1.184-1.18c0-.832-.527-1.23-1.16-1.23c-.586 0-1.168.387-1.168 1.21c0 .817.543 1.2 1.144 1.2z"/><path d="M16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82c.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79c-.852 0-1.676-.61-1.676-1.77c0-1.137.871-1.809 1.797-1.809c1.172 0 1.953.734 1.953 2.668c0 1.805-.742 2.871-2 2.871zm-2.89-5.435v5.332H5.77V8.079h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z"/></g></svg> 
        </ui-button >
        <ui-button :onclick="`contactForm('${component.attributeName}')`" class='absoluteCenterFlex' :color="component.color" :text=" component.text[gLanguage] " v-if="component.elementType == 'smartButton' && component.buttonType != 'appointment' && component.buttonType == 'contactForm'"  :style="setStyle(component, 'component')">
        </ui-button >

        <router-link class="btn-g show absoluteCenterFlex gray" v-if="component.buttonType == 'appointment'" :to="{ path:'/calendar',query: { SMLPK:'#SMARTDASH', SMLSK:'#SML#30db0c01-8f9b-4e3b-ab8d-6e7c1658c276',calendar:component.metaJSON.calendar } }" :style="setStyle(component, 'component')" target="blank">
          {{component.text[gLanguage]}}
          &nbsp; <svg  xmlns="http://www.w3.org/2000/svg" width="30" height="50" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="white"><path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zm5.402 9.746c.625 0 1.184-.484 1.184-1.18c0-.832-.527-1.23-1.16-1.23c-.586 0-1.168.387-1.168 1.21c0 .817.543 1.2 1.144 1.2z"/><path d="M16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82c.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79c-.852 0-1.676-.61-1.676-1.77c0-1.137.871-1.809 1.797-1.809c1.172 0 1.953.734 1.953 2.668c0 1.805-.742 2.871-2 2.871zm-2.89-5.435v5.332H5.77V8.079h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z"/></g></svg>
        </router-link>
        <!--Modal Edit-->
        <div :id="'modal-productShop-'+data.SK" class="modal fade modal-default" role="dialog" style="overflow-y: auto;" v-if="component.elementType == 'shopButton'  && component.shopButtonType == 'add'">
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
              <!-- Modal buttons-->
              <div class="smart-wrap">
                <div class="col-md-6">
                  <h4>{{data['product.name'].value}}</h4>
                </div>
              </div>

              <!-- Modal body-->
              <div class="smart-wrap ">
                <div class="elemet-wrap row col-md-12"  style="background:#e8e8e83d">
                  <h4 class="col-md-12" style=" padding: 10px">Sizes</h4>
                  <div class="input-wrap col-md-4" v-for="(xitem, xindex) in JSON.parse(data['sizes'].value)" :key="xindex" >
                    <a v-on:click="setParemetersProduct( xitem ,'string',xindex, 'sizes')" :id="xitem.name+'-'+xindex" class="variants">
                      <strong>{{xitem.name}}</strong> <span style="float: right;">{{xitem.price}}</span>
                    </a>
                  </div> 
                </div>
                <div class="elemet-wrap row col-md-12"  style="background:#e8e8e83d">
                  <h4 class="col-md-12" style=" padding: 10px">Add Ons</h4>
                  <div class="input-wrap col-md-4" v-for="(xitem, xindex) in JSON.parse(data['addOns'].value)" :key="xindex" >
                    <a v-on:click="setParemetersProduct( xitem ,'array',xindex, 'addOns')" :id="xitem.name+'-'+xindex" class="variants">
                      <strong>{{xitem.name}}</strong> <span style="float: right;">{{xitem.price}}</span>
                    </a>
                  </div> 
                </div>
                <div class="elemet-wrap row col-md-12"  style="background:#e8e8e83d">
                  <h4 class="col-md-12" style=" padding: 10px">Remove</h4>
                  <div class="input-wrap col-md-4" v-for="(xitem, xindex) in JSON.parse(data['remove'].value)" :key="xindex" >
                    <a v-on:click="setParemetersProduct( xitem ,'array',xindex,'remove')" :id="xitem.name+'-'+xindex" class="variants">
                      <strong>{{xitem.name}}</strong> <span style="float: right;">{{xitem.price}}</span>
                    </a>
                  </div> 
                </div>
                <div class="elemet-wrap row col-md-12"  style="background:#e8e8e83d">
                  <h4 class="col-md-12" style=" padding: 10px">Quantity</h4>
                  <div class="input-wrap col-md-4"  >
                    <input type="number" name="" id="" v-model="productParameters.qty">
                  </div> 
                </div>
                <div class="col-md-12" style="margin: 20px 0 0 0;">
                  <ui-button :color="'gray'" :position="'right'" :text="'X'" data-dismiss="modal" />
                  <ui-button style="margin: 0 10px 0 0;" v-on:click="shopButton( 'add', data ,productParameters)" :color="'green'" :position="'right'" :text="'Add to Bag'"  data-dismiss="modal"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ui-button :style="setStyle(component, 'component')" v-on:click="buttonBackEnd(component.actionJSON)" :color="component.color" :position="'center'" :text="component.text[gLanguage]" v-if=" component.elementType == 'Button' && component.actionJSON && component.attributeName != 'Button-Save' && component.attributeName != 'Button-TitleInsurance'" />
        <span v-if="component.elementType == 'Button' && !component.actionJSON && component.attributeName != 'Button-Save'" style="border: 1px dashed rgb(0, 0, 0);width: 100%;display: block;padding: 10px;text-align: center;">
          No formula found <strong>"{{component.attributeName}}"</strong> 
        </span>
        
      </div>
      
      <!-- Module  -->
      <div class="module-wrap" v-if="component.metaType == 'metaModule'">
        <h6 style="width:100%; cursor:pointer;" v-on:click="setPanel(component)">
          {{component.text[gLanguage]}}
        </h6> 
      </div>
      <!-- Table -->
      <div class="element-wrap inhert table-wrap"  v-if="component.isRepeatable == true ">
        <table class="table" id="myTable"> 
          <thead>
            <th> No </th>
            <th v-for="(column, indexcolumns) in setHeaders(index) " :key="indexcolumns"  >
              {{column.label}}
            </th>
            <th  v-if="(conditionInput('edit',component) && component.attributeName != 'SMLPhones')|| (gRecord && gRecord[1]) == 'new' " > </th>
          </thead>
          <tbody v-if="component.inputType == 'preloadList'" >
            
            <tr class="input-wrap" v-for="(item, indexItem) in models[component.attributeName].attributeValue" :key="indexItem">
              <td>{{indexItem+1}}</td>
              <td  v-for="(column, indextd) in setHeaders(index)" :key="indextd">
                <div v-if="component.readOnly == true">
                  {{models[component.attributeName].attributeValue[indexItem][column.attributeName]}}
                </div>
                <div v-else>
                  
                  <a role="button" id="dropdownMenuLinkaa" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-on:click="searchSelect= ''">
                    <input  type="text" v-if="!models[component.attributeName].attributeValue[indexItem]"  disabled style="cursor:default;">
                    <input  type="text" v-else v-model="models[component.attributeName].attributeValue[indexItem].value"  disabled style="cursor:default;">
                  </a>
                  <div class="dropdown-menu" :id="'dropdown'+component.id+'-'+indexItem+'-'+indextd" aria-labelledby="dropdownMenuLink">
                    <input type="text" v-on:keyup="f_searchSelect(component.id+'-'+indexItem+'-'+indextd )" v-model="searchSelect">
                    <a :style="unSetValueSelect(component, option)" class="dropdown-item" v-on:click="setValueSelect(index, option,component, [indexItem, column.attributeName]  )" v-for="(option, indexx) in preloadLists[component.metaJSON.shortEntity]" :key="indexx" >{{option.value}}</a>
                  </div>
                </div>
              </td>
              <td v-if="edit == false && component.readOnly != true" class="repeatable" style="padding: 0px 10px!important;">
                <div class="options">
                  <a style="cursor: pointer" v-on:click="f_repeatableAction(index, 'delete', component, indexItem)" >
                    <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                      <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                        <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                      </svg>
                    </div>
                  </a>
                </div>
              </td>
            </tr>

          </tbody>
          <tbody v-else-if="component.metaType != 'metaInput'" >
            
            <tr class="input-wrap" v-for="(item, indexItem) in models[component.attributeName].attributeValue" :key="indexItem">
              <td>{{indexItem+1}}</td>
              
              <td  v-for="(column, indextd) in setHeaders(index)" :key="indextd">
                <div v-if=" ruleTable(component, models[component.attributeName].attributeValue[indexItem])"> <!-- Rules -->
                  <span v-if="typeof models[component.attributeName].attributeValue[indexItem][column.attributeName] == 'object' "> 
                    {{ models[component.attributeName].attributeValue[indexItem][column.attributeName].value}}
                  </span>
                  <span v-else>
                    {{models[component.attributeName].attributeValue[indexItem][column.attributeName]}}
                  </span>
                </div>
                <div v-else> 
                  <!--Display Edit -->
                  <div  v-if="conditionInput('edit',component.smartComponents[indextd]) "> 
                    <div v-if="models[component.attributeName] && ( !models[component.attributeName].complete || models[component.attributeName].complete == false)">
                      <select class=" " style="width: 100%; padding: 6px 6px 3px 6px; border: 1px solid #e0e0e0;border-radius: 4px;"  v-if="column.attributeType == 'Select' " v-model="models[component.attributeName].attributeValue[indexItem][column.attributeName]" > 
                        <option v-for="(option, index) in f_validationName(component.smartComponents[indextd], 'valueOptions')" :key="index" :value="option">
                          {{ option }} 
                        </option>
                      </select>
                      <div class="check" v-if=" component.inputType == 'Boolean' ">
                        <div class="envelop" style="width: 100%;" >
                          {{f_radioSingle(component.attributeName)}}
                          <input type="checkbox"  class="box box-boolean" v-model="models[component.attributeName].attributeValue"/>
                          <span class="text" > {{component.label}} </span>
                          <span class="text" > {{models[component.attributeName].attributeValue}} </span>
                        </div>
                      </div>
                      <input type="checkbox" v-if="column.attributeType == 'Boolean'"  class=" "  style="height: 40px;" v-model="models[component.attributeName].attributeValue[indexItem][column.attributeName]">
                      <div class="inputList " v-if="column.attributeType == 'preloadList'">

                        <a class="inputMimo" v-if="models[component.attributeName].attributeValue" href="#" role="button" id="dropdownMenuLinkaa" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-on:click="searchSelect= ''">
                          <span v-if="models[component.attributeName].attributeValue[indexItem][column.attributeName].value">
                            {{ models[component.attributeName].attributeValue[indexItem][column.attributeName].value }}
                          </span> 
                          <span v-else>
                            Select Options
                          </span> 
                        </a>
                        <div  class="dropdown-menu" :id="'dropdown'+component.id+'-'+indexItem+'-'+indextd" aria-labelledby="dropdownMenuLink">
                          <input type="text" v-on:keyup="f_searchSelect(component.id+'-'+indexItem+'-'+indextd )" v-model="searchSelect">
                          <a :style="unSetValueSelect(component, option)" class="dropdown-item" v-on:click="setValueSelect(index, option,component, [indexItem, column.attributeName]  )" v-for="(option, vindex) in preloadLists[component.smartComponents[indextd].metaJSON.shortEntity]" :key="vindex">{{option.value}}</a>
                        </div>
                      </div>
                      <a class="dropdown-toggle inputMimo" v-if="column.attributeType == 'GRAPHQL' || column.attributeType == 'GRAPHQL2'" role="button" id="dropdownMenuLinka" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-on:click="searchSelect= ''">
                        <span v-if="models[component.attributeName].attributeValue[indexItem][column.attributeName].value">
                          {{ models[component.attributeName].attributeValue[indexItem][column.attributeName].value }}
                        </span>
                        <span v-else>
                          Select an Option
                        </span>
                      </a>
                      <div  v-if="column.attributeType == 'GRAPHQL' || column.attributeType == 'GRAPHQL2'" class="dropdown-menu" :id="'dropdown'+component.id+'-'+indexItem+'-'+indextd" aria-labelledby="dropdownMenuLink">
                        <input type="text" v-on:keyup="f_searchSelect(component.id+'-'+indexItem+'-'+indextd )" v-model="searchSelect" :id="'graphqlSearch-'+component.id+'-'+indexItem+'-'+indextd">
                        <span 
                        
                          v-if="component.smartComponents[indextd].allowCreate && component.smartComponents[indextd].allowCreate == true" 
                          v-on:click="
                            createRecordByGraphQl(component.smartComponents[indextd], 'graphqlSearch-'+component.id+'-'+indexItem+'-'+indextd,  index, 'graphqlSearch-'+component.id+'-'+indexItem+'-'+indextd ,component, [indexItem, column.attributeName]  );" 
                          style="cursor: pointer; display: block;position: absolute;top: 0;font-size: 32px;padding: 4px 10px;color: #838383;right: 0;"
                        >
                          +
                        </span>
            
                        <a :style="unSetValueSelect(component, option)" class="dropdown-item" v-on:click="setValueSelect(index, option,component, [indexItem, column.attributeName]  )"  v-for="(option, indexx) in component.smartComponents[indextd].valueOptions" :key="indexx">{{option.value}}</a>
                      </div>
                      <input v-if="column.attributeType == 'Text' || column.attributeType == 'Date' || column.attributeType == 'Number' || column.attributeType == 'Email' || column.attributeType == 'Counter'  || column.attributeType == 'ZipCode' || column.attributeType == null"  class=" " :type="column.attributeType" style="height: 40px;" v-model="models[component.attributeName].attributeValue[indexItem][column.attributeName]">
                      <span v-if="column.attributeType == 'createdBy'">{{models[component.attributeName].attributeValue[indexItem][column.attributeName]}}</span>
                    </div>
                    <div v-else>
                      {{models[component.attributeName].attributeValue[indexItem][column.attributeName]}}
                    </div>
                  </div>
                  <!--Display View -->
                  <div v-if="conditionInput('read', component.smartComponents[indextd]) || component.attributeName == 'SMLPhones'" >
                    
                    <span v-if="column.attributeName == 'phoneNumber'">
                      {{ formatData( 'phone',models[component.attributeName].attributeValue[indexItem].phoneNumber)}}
                    </span>
                    
                    <span v-else-if="column.attributeType == 'Boolean'">
                      <i v-if="models[component.attributeName].attributeValue[indexItem][column.attributeName] == true" class='bx bxs-check-circle' style='color:#59d000; font-size:22px; opacity:0.6;'></i>
                    </span>
                    <span v-else>
                      <span v-if="typeof models[component.attributeName].attributeValue[indexItem][column.attributeName] == 'object' "> 
                        {{ models[component.attributeName].attributeValue[indexItem][column.attributeName].value}}
                      </span>
                      <span v-else>
                        {{models[component.attributeName].attributeValue[indexItem][column.attributeName]}}
                      </span>
                    </span>
                  </div>
                  
                </div>
              </td>
              <td v-if="( conditionInput('edit',component) && component.attributeName != 'SMLPhones') || (gRecord && gRecord[1]) == 'new'" class=" repeatable" style="padding: 0px 10px!important;">
                <div class="options">
                  <a style="cursor: pointer" v-on:click="f_repeatableAction(index, 'delete', component, indexItem)" >
                    <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                      <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                        <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                      </svg>
                    </div>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else >
            
            <tr v-for="(item, zindexItem) in models[component.attributeName].attributeValue" :key="zindexItem" >
              <td>{{zindexItem+1}}</td>
              <td>
                <input :style="f_styleInpu(index)" :type="component.inputType" v-model="models[component.attributeName].attributeValue[zindexItem]">
              </td>
              <td v-if="conditionInput('edit',component) " style="padding: 0px 10px!important;">
                <div class="options">
                  <a style="cursor: pointer" id="" v-on:click="f_repeatableAction(index, 'delete', component, indexItem)" >
                    <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                      <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                        <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                      </svg>
                    </div>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <span v-if="conditionInput('edit',component) " class="add-table" v-on:click="f_repeatableAction(index, 'push' , component)">
          Add New 
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="#5a5a5a" stroke-width="2"><path stroke-linecap="round" d="M12 8v4m0 0v4m0-4h4m-4 0H8"/><circle cx="12" cy="12" r="10"/></g></svg> 
        </span>
      </div>
    </div>
  </div>
  <div v-else> 
    <!--p v-if style="padding: 20px;border: 1px dashed rgb(204, 204, 204);width: 900px;display: block;margin: 20px auto;text-align: center;color: #5f5f5f;font-weight: 600;     overflow: hidden;">  Layout structure not found <br> 
      <paper-displayer style="float:left; width:50%; display:block;" :title="'Layout'" class="" :value="layout"/>
      <paper-displayer style="float:left; width:50%; display:block;" :title="'Binds'" :value="models"/>
    </p--> 
  </div>

</template>
<script>
let tokenQuote = null
//AWS
import { smd_verifyEmail, smd_updateSmartRecord, smd_setItemState, smd_executeBackendFunctions, smd_addToCart, smd_savePaymentFormContact, smd_mutationOperation, smd_updateSmartAppointment,smd_updateSmartQuiz1, smd_customerSignUp, smd_createCognitoWithPassword, smd_stepOperation } from '../../graphql/mutations'
import { smd_getSMLItem, smd_getCart, insuranceTitle_operations, smd_queryOperation, smd_getRecordObj  } from '../../graphql/queries'
//Components
import PaperDisplayer from './PaperDisplayer.vue';
import UiButton from '../ui/UiButton.vue'
import UiCalendar from '../ui/UiCalendar.vue'
import UiModal from '../ui/UiModal.vue'
import UiCharts from '../ui/UiCharts.vue'
import UiTable from '../ui/UiTable.vue'
import UiRecursiveDisplay from '../ui/UiRecursiveDisplay.vue'
import PlEditor from '../../assets/plugins/PlEditor.vue'
import draggable from "vuedraggable";
//Tools
import { formatData, loading, checkInput, popUp, actionCalendar, processStyle, stringAdd1, orderColors } from '../../store/tools.js';
import Swal from 'sweetalert2'
import { API, Storage,Auth } from "aws-amplify";
import Vuex from "vuex"; // Use a Variables Globals
import trumbowyg  from 'trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css';
import { v4 as uuidv4 } from 'uuid';  
import PaperLayers from './PaperLayers.vue';


export default {
  name: "paper-layout",
  components:{
    draggable,
    PaperDisplayer,
    UiButton,
    UiCalendar,
    UiCharts,
    UiModal,
    PlEditor,
    UiRecursiveDisplay,
    UiTable,
    PaperLayers
  },
  data() {
    return {
      //responsive
      responsive:'0',
      responsiveText :'',
      widthScreen:'',
      //TOTALS
      results:'',
      fileView: [],
      securityFile: 'public',
      descriptionFile: '',
      smName: "dfault",
      w_fileColumns: [{attributeName: "name", label:"Name"}], //Number of combos
      htmlTag : {tag:'none', help:'none'},
      totals : [] ,
      helpC: true,
      anchor: 0,
      searchSelect: '',
      test: '',
      //Bind Composer
      phone:{},
      inputValue:{},
      currency:{},

      //Internal Table
      itemView: null,
      modelsInternalTable:[] ,
      layoutInternalTable:null,
      //Shopping

      //What
      modelsStr: [],

      //productParameters
      productParameters:{qty:1},

      phoneNumber:'',
      srcIdioms:{
        en:'English',
        es:'Spanish',
        it:'Italian',
        fr:'French',
        de:'German',
      },
      //Title
      titeInsurance:'',
      //Calendar
      calendarDays: ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'],
      calendarHours: [],
      mes:1,
      id:'',
      // steps
      //step:'step1'  
      //choice: 1,
      //billing
      tandc:false,   
    }
  },
  props: {
    layout:{
      type: Array,
    },
    models:{
      type: Object,
      default: null
    },
    parent:{
      type: String,
      default: 'general'
    },
    edit:{
      type: Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {
        PK: null,
        SK: null,
      }
    },
    record:{
      type: Object,
      default: {  }
    },
    paperPermissions:{
      type: Object,
      default:{
        usersCanEdit:[],
        usersCanRead:[],
        groupsCanEdit:["Admin","Superadmin","Editor"],
        groupsCanRead:["Admin","Superadmin","Editor"],  
      }
    },
    filterSmarForm:{
      type: String,
      default: 'all'
    },
    layoutCategory:{
      type: String,
      default: 'form'
    },
    idioms:{
      type: Array,  
      default: ['en']
    },
    palette:{
      type: Array,  
      default: []
    },

  },
  created() {
    this.id =uuidv4()
    this.phoneList = [ ]
    let container = 1200
    if (this.models.responsive) {
      this.responsive = this.models.responsive
    } else {
      
    }
    this.widthScreen = window.screen.width
    switch ( parseInt(this.responsive) ) {
      case 0: this.responsiveText = 'desk'; break;
      case 1: this.responsiveText = 'laptop'; break;
      case 2: this.responsiveText = 'tablet'; break;
      case 3: this.responsiveText = 'phone'; break;
    }
  },
  mounted() {
  },
  methods: {
    //Global Methods
    ...Vuex.mapMutations(['setChoice','setCalAlert','setGLanguage','setQuiz','setTargetFiled','setCartData',"SetOrganizationID","SetPageForm","SetPageTable","SetPageName","SetPageQuery", "SetPageMeta", "SetPageModule", "SetPageEntity", "setRecord", "setProductParameters","setbRecord"]),
    ...Vuex.mapActions(["getRecord"]),
    //gLanguage
    setLanguage(data){
      this.setGLanguage(data)
    },
    saveForce(){
      var save = new Event('click');
      let buttonSave = document.getElementById('save-'+this.data.SK);
      buttonSave.dispatchEvent(save);
    },
    ruleTable(component, binds){ // First Version
      let condition = false
      try {
        let user = this.user
        if (eval(component.rules[0].condition)) {
          condition = true
        }
      } catch (error) {
      }
      if (binds.completed == 'Yes') {
        //condition = true
      }
      return condition
    },
    conditionInput(type, component){
      if (component.layoutItemType =="frontEnd" ) {
        return
      }
      
      let readOnly = component.readOnly
      
      let value = false
      let globalPermission ={
        read: true,
        edit: true
      }
      if (this.$router.currentRoute.value.path == '/inf') {
        globalPermission.edit =this.webSite.ACLPermits.write
        globalPermission.read =this.webSite.ACLPermits.read
      }
      let existValue = (this.models[component.attributeName]!= undefined) //If value exist
      let valueInput = null
      if (existValue) {
        valueInput = this.models[component.attributeName]
      }
      let isRepeatable = component.isRepeatable // If is is Repeatable
      let input = (component.metaType == 'metaInput') // If is input
      let file = false
      if (input) {
        file = component.inputType.toLowerCase() == 'file' // If is file
      }

      //if ((input && !isRepeatable) || file) {
      if (component.layoutItemType == "backEnd" || input) {
        
        switch (type) {
          case 'edit':
            if (!readOnly || file ) {
              if (existValue) {
                if (valueInput.complete == undefined || valueInput.complete == false || file) {
                  value = true
                }
              }
              else{
                value = true
              }
              
            }
            if (this.gRecord && this.gRecord[0] && this.gRecord[0].edit == false) {
              value = false
            }
            //((component.metaType == 'metaInput' && component.isRepeatable == false && component.readOnly == false && component.attributeName != 'oldSystemClientId') || (!component.readOnly && component.metaType == 'metaInput')) && (models[component.attributeName] && ( !models[component.attributeName].complete || models[component.attributeName].complete == false))|| ( component.metaType == 'metaInput' && component.inputType == 'File'  ) 
          break;
          case 'read':
            if (existValue && !file) {
              //console.log('readOnly: ' + readOnly);
              //console.log('valueInput.complete: ' + valueInput.complete);
              //console.log('file: ' + file);
              if ( (readOnly || (valueInput.complete && valueInput.complete == true )) || file ) {
                value = true
              }
            }
            else{
              if (readOnly && !file) {
                value = true
              }
            }
            if (this.gRecord && this.gRecord[0] && this.gRecord[0].edit == false) {
              value = true
            }
            setTimeout(() => {
              //console.log(document.getElementById(component.id));
              //console.log(component.attributeName + ' - '+type + ' - '+ value +' - '+globalPermission.read);
            }, 100);
            //(component.isRepeatable == false && component.metaType == 'metaInput' && component.inputType != 'File') && ((component.metaType == 'metaInput' && component.inputType != 'File' && component.isRepeatable == false &&  component.readOnly == true && component.attributeName != 'oldSystemClientId') || (models[component.attributeName] && models[component.attributeName].complete == true))
          break;
        }
      }
      
      return value
    },
    actionPlEditor(data,att, action){
      switch (action) {
        case 'set':
          setTimeout(() => {
            document.getElementById(data).innerHTML =this.models[att].attributeValue
          }, 500);
        break;  
        case 'check':
          if(document.getElementById(data).innerHTML != this.models[att].attributeValue ){
            var save = new Event('click');
            let buttonSave = document.getElementById('pl-save-'+data.replace('pl-editor-',''))
            console.log(buttonSave);
            buttonSave.dispatchEvent(save);
          }
        break;  
        case 'save':
          this.models[att].attributeValue = document.getElementById(data).innerHTML
        break;  
      }
    },
    //Modal File
    styleModal(modal){
      console.log(Math.floor($('#modal-update').scrollTop()));
      document.getElementById(modal).style.paddingTop = Math.floor($('#modal-update').scrollTop())+'px'
      console.log(document.getElementById(modal).style.paddingTop);
    },
    //Tools
    claerBind(component){
      this.models[component.attributeName].attributeValue = ''
    },
    previewColors(data){
      try {
      //console.clear()
        const regex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/g;
        const hexValues = data.match(regex);
        console.log(hexValues);
        return hexValues
      } catch (error) {
        console.log(error);
      }
    },
    auto_grow(element) {
      document.getElementById(element).style.height = "5px";
      document.getElementById(element).style.height = (document.getElementById(element).scrollHeight)+"px";
      console.log(element);
      console.log(document.getElementById(element));
    },
    actionCalendar(action, id, data) {
      switch (action) {
        case 'range':
          console.clear()
          this.calendarHours = []
          try {
            if (this.webSite.availability[this.calendarDays[id]]) {
              console.log(this.webSite.availability)
              this.webSite.availability[this.calendarDays[id]].forEach(element => {
                let range = element.split('-')
                let final = new Date('February 10, 2022 '+ range[1])
                let init = new Date('February 10, 2022 '+ range[0])
                let hInit = parseInt(range[0].split(':'));
                let duration =parseInt(this.webSite.duration)/60
                let hoy = new Date().toGMTString().slice(0,16) === new Date(data).toGMTString().slice(0,16)
                let hours = ((((((final-init)/1000))/60)/60)/ duration)
                for (let index = 0; index < hours; index++) {
                  let push = 'none'
                  if(hInit % 1 == 0){
                    push = JSON.stringify(hInit)+':00'
                  }else{
                    push = JSON.stringify(Math.floor(hInit-duration) )+':'+ '30'
                  }
                  let local = new Date().toLocaleString('en-US', {timeZone: this.webSite.timeZone})
                  console.log(new Date(local).getHours() < hInit, new Date(local).getHours()+' - '+ local +" - "+ hInit +' hoy? '+ hoy);
                  if (hoy) {
                    if (new Date(local).getHours() < hInit) {
                      
                      this.calendarHours.push(push)
                    }
                  }else{
                    this.calendarHours.push(push)
                  }
                  hInit = hInit + duration
                }
              });
            }else{
              this.calendarHours = ['not available']
              
            }
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
          //this.calendarHours ={}
        break;
        case 'display':
          try {
            if (id != 'not available') {
              let meet = id.split(':')
              let resp = meet[0]+':'+meet[1] +' am'
              let hr = parseInt(meet[0])
              if(hr > 11){
                if (hr>12) {
                  resp = meet[0]-12+':'+meet[1] +' pm'
                } else{
                  resp = meet[0]+':'+meet[1] +' pm'
                }
              }  
              return  resp
            }
          } catch (error) {
            
          }
          return 'not available'
        break;
        default:
          let date = {
            day: new Date(data).getDate(),
            month:new Date(data).getMonth(),
            year:new Date(data).getFullYear(),
            availability : []
          }
          if (this.models['date'].attributeValue) {
            date.select  =new Date(this.models['date'].attributeValue)
          }
          for (const key in this.webSite.availability) {
            date.availability.push(this.calendarDays.indexOf(key))
          }
          //actionCalendar('next', component.id, models['date'].attributeValue)
          actionCalendar(action, id, date)
        break;
      }
    },
    deleteComplete(att){
      try {
        delete this.models[att].complete
      } catch (error) {
        
      }
    },
    formatData(format, data){
      return formatData(format, data)
    },
    setStyleComponent(element){
      let style = element.styleComponent
      try {
        let palette = this.palette
        let regexp = /\[.+?\]/g;
        let bind = [...style.matchAll(regexp)];
        
        for (let index = 0; index < bind.length; index++) {
          let parameter = JSON.parse(bind[index][0])
          style = style.replace(bind[index][0], palette[parameter[1]])
        }
        
      } catch (error) {
        console.log(error);
      }
      return style
    },
    valueValidation(data, name, att){
      try {
        return data[name][att]
      } catch (error) {
        return data
      }
    },
    goodFormat(data, format){
      let vdata = ''
      try {        
        switch (format) {
          case 'string':
            vdata = JSON.stringify(data, null,4)
          break;
          case 'json':
            vdata =JSON.parse(JSON.stringify(data))
          break;
        }
        return vdata
        
      } catch (error) {
        console.log('-->Good Format Error: '+format+'\n', data);
      }
    },
    async actionButton(component, action){
      console.log(component, action);
      let query = { }
      let pushData = ''
      let pullData = ''
      let url
      if (action) {
        let data = component
        console.clear();
        loading('show', 'updateRecord')
        console.log('>> Data Record to Update: ',data);
        //console.log('>> Mode: ',mode);
        let query = { }
        switch (action) {
          case 'updateCalendar':
            try {
              let ORGPK = this.publicOrganization
              let SMLPK = this.publicOrganization
              if (this.$router.currentRoute.value.query.ORGPK != undefined) {
                ORGPK = this.$router.currentRoute.value.query.ORGPK
              }else{
                ORGPK = this.publicOrganization
              }
              if (this.$router.currentRoute.value.query.SMLPK != undefined) {
                SMLPK = this.$router.currentRoute.value.query.SMLPK
              }else{
                SMLPK = ORGPK
              }
              //Set Mode and Config
              //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
              query = {
                shortEntity: "CAL",
                PK: ORGPK,
                SK: data.item.SK,
                attributesStr: JSON.stringify(this.models) ,
                entity: {PK: '#SMARTDASH',  SK: "#ENT#98c2ca2b-8a21-4421-a6ec-3599750f6be2"},
                form: {PK:SMLPK,SK:this.$router.currentRoute.value.query.SMLSK},
                parent:  data.item.parent,
                usersCanEdit: [ "@author"],
                usersCanRead: [ "@author"] ,
                groupsCanEdit: [ "Admin", "Editor","Superadmin"],
                groupsCanRead: [ "Admin", "Editor","Superadmin"],
              }
              if (query.SK == '') {
                query.SK = undefined
              }
              console.log(' >> Push Data <<', query, this.models);
              //Mutation=
              pullData = await API.graphql({
                query: smd_updateSmartAppointment, //smd_updateSmartRecord
                variables: { 
                  input:query
                },
              })
              pullData = pullData.data.smd_updateSmartAppointment
              console.log(' >> Successful update <<' ,pullData);
              this.record.item = JSON.parse(pullData.data)
            } catch (error) {
              popUp(error, 'cach', 'err')
              console.log(error)
            }
          break;
        }
        if (pullData.smd_response.statusCode != 200) {
          popUp(pullData.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (pullData.smd_response.popAlert == true) {
          popUp(pullData.smd_response, 'response', 'scc')
        }else{
          console.log(' >> Successful update <<' ,pushData);
          loading('close','updateRecord')
          document.getElementById('calendar-alert').classList.add('show'); 
          this.setCalAlert(true)
          //location.reload()
        }
      }else{
        switch (component.buttonType) {
          case 'link':
            window.open(component.url, '_blank')
          break;
          case 'step':
            try {
              
              if(this.$router.currentRoute.value.path == '/sales'){
                this.loading('show')
                this.models.form.paymentParameters=tokenQuote
              }
              pushData={
                PK: this.publicOrganization,
                data: JSON.stringify(this.models.form),
                operation: component.stepType,
              }
              console.log('pushData: ', pushData);
              pullData = await API.graphql({
                query: smd_stepOperation,
                variables: pushData
              })
              pullData = pullData.data.smd_stepOperation  
              console.log(pullData);
              if (pullData.smd_response.statusCode != 200) {
                switch (pullData.smd_response.statusCode) {
                  case '001':
                    //Pending
                    console.log(pullData.smd_response );
                    Swal.fire({
                      title: pullData.smd_response.userMessage,
                      //icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: orderColors(this.palette)[2],
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Go to login'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        //console.log('push hacia el login');
                        this.$router.push({path:'/login' ,})
                      }
                    })
                  break;
                  case '002':
                    //Pending
                    console.log(pullData.smd_response );
                    Swal.fire({
                      title: pullData.smd_response.userMessage,
                      //icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: orderColors(this.palette)[2],
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Go to login'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        //console.log('push hacia el login');
                        this.$router.push({path:'/login' ,})
                      }
                    })
                  break;
                
                  default:
                  break;
                }
                //popUp(pullData.smd_response, 'response', 'warning')
                loading('hide', 'getItem')
                return
              }
              try {
                pullData = JSON.parse(pullData.data)
                console.log('pullData')
                console.log(pullData)
                if (pullData.SK) {
                  this.models.auth = pullData
                }
                if (pullData.amount) {
                  this.models.amount = pullData.amount
                }
                
              } catch (error) {
                
              }
              setTimeout(() => {
                this.setPaymentMethods()
              }, 1000);
              this.loading('close')
              this.models.step = stringAdd1(this.models.step)
            } catch (error) {
              console.log(error);
              popUp(error, 'cach', 'err')
            }
            /*
              switch (component.stepType) {
                case 'display':
                  this.models.step = stringAdd1(this.models.step)
                break;
                case 'setPass':
                  try {
                    pushData={
                      PK: this.publicOrganization,
                      data: JSON.stringify(this.models.form),
                    }
                    console.log('pushData: ', pushData);
                    pullData = await API.graphql({
                      query: smd_createCognitoWithPassword,
                      variables: pushData
                    })
                    pullData = pullData.data.smd_createCognitoWithPassword
                    console.log(pullData);
                    if (pullData.smd_response.statusCode != 200) {
                      popUp(pullData.smd_response, 'response', 'err')
                      loading('hide', 'getItem')
                      return
                    }
                    setTimeout(() => {
                      this.setPaymentMethods()
                    }, 1000);
                    this.models.step = stringAdd1(this.models.step)
                  } catch (error) {
                    console.log(error);
                    popUp(error, 'cach', 'err')
                  }
                break;
                case 'form':
                  try {
                    pushData={
                      orgPK: this.publicOrganization,
                      data: JSON.stringify(this.models.form),
                      originURL: location.href,
                    }
                    console.log('pushData: ', pushData);
                    pullData = await API.graphql({
                      query: smd_customerSignUp,
                      variables: pushData
                    })
                    console.log(pullData);
                    pullData = pullData.data.smd_customerSignUp
                    if (pullData.smd_response.statusCode != 200) {
                      popUp(pullData.smd_response, 'response', 'err')
                      loading('hide', 'getItem')
                      return
                    }
                    pullData = JSON.parse(pullData.data)
                    console.log(pullData)
                    this.models.auth = pullData
                    this.models.step = stringAdd1(this.models.step)
                    setTimeout(() => {
                      this.setPaymentMethods()
                    }, 1000);
                    
                  } catch (error) {
                    console.log(error);
                    popUp(error, 'cach', 'err')
                  }
                break;
                case 'choice':
                  this.models.step = stringAdd1(this.models.step)
                break;
                case 'confirmEmail':
                  try {
                    pushData={
                      email:  JSON.stringify(this.models.form), PK: this.publicOrganization, data: JSON.stringify(this.models.form)
                    }
                    console.log('pushData',pushData)
                    pullData = await API.graphql({
                      query: smd_verifyEmail,
                      variables: pushData
                    })
                    console.log(pullData);
                    pullData = pullData.data.smd_verifyEmail
                    if (pullData.smd_response.statusCode != 200) {
                      popUp(pullData.smd_response, 'response', 'err')
                      loading('hide', 'getItem')
                      return
                    }
                    console.log(pullData)
                    this.models.step = stringAdd1(this.models.step)
                    setTimeout(() => {
                      this.setPaymentMethods()
                    }, 1000);
                    
                  } catch (error) {
                    console.log(error);
                    popUp(error, 'cach', 'err')
                  }
                break;
              }
            */
          break;
          case 'appointment':
            url = this.$router.resolve( { path:'/calendar',query: { SMLPK:'#SMARTDASH', SMLSK:'#SML#30db0c01-8f9b-4e3b-ab8d-6e7c1658c276',calendar:component.metaJSON.calendar } });
            window.open(url.href, '_blank')
          break;
          case 'quizAction':
            console.clear();
            loading('show', 'updateRecord')
            if (checkInput(this.layout, this.models) == false) {
              loading('close','updateRecord')
              return
            }
            console.log();
            let data = this.record
            console.log('>> Data Item: ',data);
            //console.log('>> Mode: ',mode);
            let query = { }
            let pushData = ''
            try {
              //Set Mode and Config
              //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
              query = {
                shortEntity: 'QUI',
                PK: this.publicOrganization,
                SK:  data.item.SK,
                baseURL: location.host,
                attributesStr: JSON.stringify(this.models) ,
                entity: {PK: '#SMARTDASH', SK: '#ENT#961aa971-3017-4f13-8f52-0b3d513313a3'},
                form: JSON.parse(data.layoutID),
                parent:  data.item.parent,
                usersCanEdit: [],
                usersCanRead: [] ,
                groupsCanEdit:["Admin","Superadmin","Editor"],
                groupsCanRead:["Public"],   
                
              }
            
              if (query.SK == '') {
                query.SK = undefined
                query.PK = this.publicOrganization
              }
              console.log(' >> Push Data <<\n', query, component);
              //Mutation
              pushData = await API.graphql({
                query: smd_updateSmartQuiz1, //smd_updateSmartQuiz // smd_updateSmartQuiz1
                variables: { 
                  input:query,
                  action: JSON.stringify(component.metaJSON)
                },
              });
              pushData = pushData.data.smd_updateSmartQuiz1
              if (pushData.smd_response.statusCode != 200) {
                popUp(pushData.smd_response, 'response', 'err')
                loading('hidde', 'getItem')
                return
              }
              else if (pushData.smd_response.popAlert == true) {
                popUp(pushData.smd_response, 'response', 'scc')
              }
              console.log(JSON.parse(pushData.data));
              if(JSON.parse(pushData.data).type == 'redirect'){
                  console.log(JSON.parse(pushData.data).url)
                  window.open(JSON.parse(pushData.data).url, "_self" );
              }else{
                document.getElementById('screen-wrap').classList.add('show')
                console.log(' >> Successful update <<' ,pushData);
                document.getElementById('returnQuiz').innerHTML = JSON.parse(pushData.data).message
              }
              
              //query.response = pushData.smd_response
              //this.setQuiz(query);
              
              //this.record.item = pushData
            } catch (error) {
              console.log(error);
              popUp(error, 'cach', 'err')
            }
            setTimeout(() => {
              this.sub= false
              this.loading('close','updateRecord')
            }, 200);
          break;
          case 'queryButton':
            try {
              query ={
                orgPK: this.publicOrganization,
                templateSK: component.attributeName,
                item: String,
                operation: String
              }
              pushData = await API.graphql({
                query: smd_queryOperation,
                variables: query
              })
              pushData = pushData.data.smd_queryOperation
              console.log(pushData);
              //Response
              if (pullData.smd_response.statusCode != 200) {
                popUp(pullData.smd_response, 'response', 'err')
                loading('hidde', 'getItem')
                return
              }
              else if (pullData.smd_response.popAlert == true) {
                popUp(pullData.smd_response, 'response', 'scc')
              }
            } catch (error) {
              console.log(error);
              popUp(error, 'cach', 'err')
            }
          break;
          case 'mutationButton':
            try {
              query ={
                orgPK: String,
                templateSK: String,
                item: String,
                operation: String
              }
              pullData = await API.graphql({
                query: smd_mutationOperation,
                variables: query
              })
              pullData = pullData.data.smd_mutationOperation
              console.log(pullData);
              if (pullData.smd_response.statusCode != 200) {
                popUp(pullData.smd_response, 'response', 'err')
                loading('hidde', 'getItem')
                return
              }
              else if (pullData.smd_response.popAlert == true) {
                popUp(pullData.smd_response, 'response', 'scc')
              }
            } catch (error) {
              console.log(error);
              popUp(error, 'cach', 'err')
            }
          break;
        }
      }
    },
    loading(action){
      try {        
        if (action == 'show') {
          document.getElementById("loading").classList.add('show')
          document.getElementById("body").classList.add("noScroll")
        }else{
          document.getElementById("loading").classList.remove('show')
          document.getElementById("body").classList.remove("noScroll")
        }
      } catch (error) {
        console.log(error);
      }
    },
    totalQuote(data){
      let value = 0
      for (let index = 0; index < data.steps.length; index++) {
        const element = data.steps[index];
        value = value + element.amount
      }
      return value
    },
    setDate(date) {
      //console.clear()
      let value = [date.split('T')[0]]
      let time = ''
      try {
        let dateToday = new Date().toISOString().split("T")[0];
        let difenrence = new Date().getTimezoneOffset()/60
        if (date.split("-")[0] == dateToday.split("-")[0] ) {
          
          if (date.split("-")[1] == dateToday.split("-")[1]) {
            switch (parseInt(date.split("-")[2])) {
              case parseInt(dateToday.split("-")[2])+1:
                value[0] = 'Tomorrow'
              break;
              case parseInt(dateToday.split("-")[2]):
                console.log();
                value[0] = 'Today'
              break;
              case parseInt(dateToday.split("-")[2])-1:
                value[0] = 'Yesterday'
              break;
              default:
                value[0] = new Date(date).toLocaleDateString(navigator.gLanguage)
              break;
            }
          }
          let timeData = date.split('T')[1]
          time =parseInt(timeData.split(':')[0]) - difenrence+':'+timeData.split(':')[1]+':'+timeData.split(':')[2]
          if (time.split(':')[0].length == 1) {
            time ='0'+time
          }
          function minuts(data, local ) {
            if (local == data) {
              console.log('Just Now');
            }
            if (local == data-1  ) {
              console.log('1 minutes ago');
            }
            if (local == data-2  ) {
              console.log('1 minutes ago');
            }
            if (local == data-3  ) {
              console.log('1 minutes ago');
            }
            if (local == data-4  ) {
              console.log('1 minutes ago');
            }
            if (local == data-5  ) {
              console.log('1 minutes ago');
            }
            if (local == data-10  ) {
              console.log('10 minutes ago');
            }
            if (local == data-15  ) {
              console.log('15 minutes ago');
            }
          }
          minuts(10, 10);
          value[1] =new Date( date.split('T')[0]+'T'+time).toLocaleTimeString()
        }
      } catch (error) {
        console.log(error);
      }
      return value ;
    },
    phoneFormat(data){
      let value = data
      /*try {
        if (value.length >5) {
            value = value.slice(0,2)+ '('+value.slice(2,5)+')' + value.slice(5,8)+'-'+value.slice(8)
          return value
        }else{
          value = '200 000-0000'
        }
      } catch (error) {
        console.log(error);
      }*/
      return value
    },
    currencyFormat(data){
      let value  = data
      try {
        value = (parseInt(value)).toLocaleString('en-US', { style: 'currency', currency: 'USD'})
      } catch (err) {
        console.log(err);
      }
      return value
    },
    //Payment Section
    async setPaymentMethods(){
      let form = null
      //------
      let pullData = null
      let pushData = null
      let type = null
      try {
        pushData={
          PK: '#SMARTDASH',
          SK: "#ORG#SMARTDASH",
        }
        pullData = await API.graphql({
          query: smd_getRecordObj,
          variables: pushData
        })
        pullData = pullData.data.smd_getRecordObj
        pullData = JSON.parse(pullData.data)
        //console.log(pullData);
        type = this.organizationData.paymentMode.toLowerCase() 
      } catch (error) {
        console.log(error);
        //popUp(error, 'cach', 'err')
      }
      //------
      setTimeout(() => {
        //console.clear()
        let authorization = null;
        form = document.getElementById('cardForm')
        if (type == 'production') {
          authorization = pullData.clientsideToken.value
        } else {
          authorization = pullData.sandboxClientsideToken.value;
        }
        braintree.client.create({ authorization: authorization }, 
        function (err, clientInstance) {
          if (err) {
            console.error(err);
            return;
          }
          createHostedFields(clientInstance);
        });
      }, 500);
      function createHostedFields(clientInstance) {
        braintree.hostedFields.create({
          client: clientInstance,
          styles: {
            'input': {
              'font-size': '16px',
              'font-family': 'courier, monospace',
              'font-weight': 'lighter',
              'color': '#555555'
            },
            ':focus': {
              'color': 'black'
            },
            '.valid': {
              'color': '#08a414f7'
            }
          },
          fields: {
            number: {
              selector: '#card-number',
              //placeholder: '4111 1111 1111 1111'
            },
            cvv: {
              selector: '#cvv',
              //placeholder: '123'
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'MM/YYYY'
            },
            postalCode: {
              selector: '#postal-code',
              //placeholder: '11111'
            }
          }
        }, function (err, hostedFieldsInstance) {
          if (err) {
            console.error(err);
            return;
          }
          var tokenize = function (event) {
            event.preventDefault();
            hostedFieldsInstance.tokenize(function (err, payload) {
              if (err) {
                console.log(err);
                tokenQuote = JSON.stringify({token:null, processorResponse:err })
                Swal.fire({
                  icon: 'error',
                  title: 'Something went wrong!' ,
                  text: JSON.stringify(err.message),
                })
                return;
              }
              tokenQuote = JSON.stringify({token:payload.nonce, processorResponse:payload })
              //alert('Submit your nonce (' +  payload.nonce + ') to your server here!');
            });
          };
          
          form.addEventListener('submit', tokenize, false);
          //form.on("submit", tokenize, false);
        });
      }
    },
    async token(){
      console.log("llegue a token")
      console.log(tokenQuote)
      let pullData = null
      try {
        loading('show')
        let pushData =  {
          orgPK: this.publicOrganization ,// data.PK,
          paymentParameters: tokenQuote,
          contactSK: this.models.auth.SK,
        }
        console.log(pushData);
        pullData = await API.graphql({
          query : smd_savePaymentFormContact,
          variables:pushData
        })
        pullData = pullData.data.smd_savePaymentFormContact
        if (pullData.smd_response.statusCode != 200) {
          popUp(pullData.smd_response, 'response', 'z')
          loading('hide', 'getItem')
          return
        }
        console.log(pullData, tokenQuote);
        //this.models.step = stringAdd1(this.models.step)
        //this.getData()
          loading('hidden')
      } catch (error) {
        loading('hidden')
        console.log(error);
        popUp(error, 'cach', 'err')
        }
    },
    async action(){
      loading('show', 'updateRecord')
      setTimeout(() => {
        if(this.$router.currentRoute.value.path == '/sales' ){
          this.actionButton({buttonType:"step",stepType:"smd_oneTimeSale"})
        }else{
          this.token()
        }
      }, 1000);
    },
    //Action with Records
    updateRecord(record, method){
      console.log('updateRecord');
      if (record.allowOpen == true) {
        this.loading('show')
        this.setRecord(record)
        this.getRecord(record)
        if (method == 'page') {
  
          setTimeout(() => {
            this.$router.push({
              query:{name:'edit'}
            });
            this.loading('close')
          }, 1000);
        }else{
          this.getRecord(record)
          let route = this.$router.resolve({
            query:{name:'edit'}
          });
        }
      }
    },
    //Set Modules for Navegation
    async setPanel(data){
      console.log(JSON.stringify(data,null, 4));
      this.SetPageModule(data.SK)
      this.SetPageForm(data.att.formSK)
      this.SetPageName(data.att.text);
      this.SetPageTable(data.att.viewSK);
      this.SetPageEntity(data.att.entity);
      this.SetPageQuery(data.att.dataQuery);
      this.$router.push({ path: `/pg`, query: { name: data.att.dataQuery } })
    },
    //Component Funcionality
    inputAction(input, type , bind ){
      let element= document.getElementById(input.attributeName)
      switch (type) {
        case 'email':
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const vaild = emailRegex.test(this.inputValue[input.attributeName]);
          if (vaild) {
            if (bind) {
              this.models.form[input.attributeName] = this.inputValue[input.attributeName]
            }else{
              this.models[input.attributeName].attributeValue = this.ark[input.attributeName]
            }
            if (!element.classList.contains('inputGood')) {
              element.classList.add('inputGood');
            }
          } else {
            if (element.classList.contains('inputGood')) {
              element.classList.remove('inputGood');
            }
            if (!element.classList.contains('inputBad')) {
              element.classList.add('inputBad');
            }
          }
        break;
      }
      console.log(input)
      if(input.compareTo && input.compareTo ==  true){
        let message = document.getElementById('input-compareTo'+input.attributeName)
        let messageB = document.getElementById('input-compareTo'+input.targetBind)
        if (bind) {
          if (this.models.form[input.attributeName] ==  this.models.form[input.targetBind]) {
            if (!message.classList.contains('none')) {
              message.classList.add('none');
              messageB.classList.add('none');
            }
          }else{
            if (message.classList.contains('none')) {
              message.classList.remove('none');
              messageB.classList.remove('none');
            }
          }
        }else{
          this.models[input.attributeName].attributeValue = this.ark[input.attributeName]
        }
      }
      if(input.onChange && input.onChange ==  true){
        console.log('On Change');
        if (this.models[input.attributeName].attributeValue == input.onChangeCompareTo) {
          console.log('On Change condition', input.onChangeResult);
          this.models[input.targetBind].attributeValue = input.onChangeResult
        }
        
      }
    },
    async buttonBackEnd( formula ) {
      this.loading('show');
      let pushContent = ''
      try {
        pushContent = await API.graphql({
          query: smd_executeBackendFunctions, //Custom Button
          variables: { 
            PK: this.publicOrganization,
            SK: '',
            formula: JSON.stringify(formula)
          },
        });
        pushContent = pushContent.data.smd_executeBackendFunctions
        console.log(pushContent);
        this.loading('hide');
        Swal.fire(
          'Success',
          JSON.stringify(pushContent, null, 4),
          'success'
        )
      } catch (error) {
        this.loading('hide');
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!' ,
          text: JSON.stringify(error),
        })
      }
    },
    bindComposer(att, type){
      //this.$.appContext.app.unmount();
      console.log(this[type]);
      try {
        if (!this[type][att] && this[type][att] != '') {
          switch (type) {
            case 'phone':
              this.phone[att] = this.models[att].attributeValue
            break;
            case 'currency':
              this.currency[att] = this.models[att].attributeValue
            break;
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    inputPhone(att, type, event){
      if (event.key == 'Backspace') {
        return
      }
      let value = this.phone[att]
      if (value.length == 1) {
        value = '('+ value
      }
      if (value.length == 4) {
        value = value + ') '
      }
      if (value.length == 9) {
        value = value + '-'
      }
      let check = value[value.length-1]
      
      if (isNaN(check) && value.length != 5 && value.length != 10) {
        value = value.replace(value.slice(-1),'')
      }
      if (type == 'form') {
        this.models.form[att] = '+1'+value.replace('(','').replace(')','').replace('-','').replace(' ','')
      }else{
        this.models[att].attributeValue = '+1'+value.replace('(','').replace(')','').replace('-','').replace(' ','')
      }
      this.phone[att] = value
    },  
    inputCurrency(att){
      let value = this.currency[att].replace('.','')
      if (value.length > 2) {
        let decimal = value.slice(-2)
        let amount = value.slice(0,-2)
        value = amount+'.'+decimal
      }
      
      let check = value[value.length-1]
      
      if (isNaN(check)) {
        value = value.replace(value.slice(-1),'')
      }
      console.log(value);
      console.log(parseFloat(value));
      this.models[att].attributeValue = value
      this.currency[att] = value
    },
    //Shop
    async shopButton(type, data ,parameters) {
      console.clear()
      console.log(type); 
      switch (type) {
        case 'add': 
          console.log(this.goodFormat(data, 'json'));
          console.log(this.goodFormat(parameters, 'string'));
          this.loading('show');
          let pushContent = ''
          
          try {
            pushContent = await API.graphql({
              query: smd_addToCart, //Custom Button
              variables: { 
                PK: data.PK,
                SK: data.SK,
                parameters: JSON.stringify(parameters),
              },
            });
            pushContent = pushContent.data.smd_addToCart
            console.log(pushContent);
            this.loading('hide');
            Swal.fire(
              'successfully!',
              JSON.stringify(pushContent, null, 4),
              'success'
            )
          } catch (error) {
            this.loading('hide');
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong!' ,
              text: error.errors[0].message,
            })
          }
        break;
        case 'view':
          let pullData = ''
          let org = this.publicOrganization
          if (this.publicOrganization == undefined || this.publicOrganization == undefined || this.publicOrganization == '') {
            org = this.organizationID
          }
          console.log( org );
          pullData  = await API.graphql({
            query: smd_getCart,
            variables:{
              orgPK:org
            }
          }) 
          pullData = pullData.data.smd_getCart
          console.log(JSON.parse(pullData.data));
          this.setCartData(JSON.parse(pullData.data))
        break;
      }
    },
    setParemetersProduct(data, type, index, name){
      let element = document.getElementById(data.name+'-'+index)
        if (this.productParameters[name] == undefined) {
          this.productParameters[name] = []
          document.getElementById(data.name+'-'+index).classList.add('active')
          this.productParameters[name].push(data.name)
        }else{
          if (element.classList[element.classList.length-1] == 'active') {
            document.getElementById(data.name+'-'+index).classList.remove('active')
            let position = this.productParameters[name].indexOf(data.name)
            this.productParameters[name].splice(position,1)
          }else{
            document.getElementById(data.name+'-'+index).classList.add('active')
            this.productParameters[name].push(data.name)
          }
        }
      /*switch (type) {
        case 'string':
          
        break;
        case 'array':
        break;
      }*/

    },
    //Step
    divAction(component){
      if (component.divType && component.divType == 'choice') {
        if (this.models.choice == this.models.SK) {
          this.models.choice = null
        }else{
          this.models.choice = this.models.SK
        }
        this.$forceUpdate();
        console.log( this.models.choice);
      }
    },
    //Layout Funcionality
    actionLink(component){
      console.log(component);
    },
    async quizActionButton(component) {  
      console.clear();
      this.loading('show', 'updateRecord')
      if (checkInput(this.layout, this.models) == false) {
        loading('close','updateRecord')
        return
      }
      let data = this.record
      console.log('>> Data Item quizActionButton: ',data);
      //console.log('>> Mode: ',mode);
      let query = { }
      let pushData = ''
      try {
        data.item.baseURL = location.host
        //Set Mode and Config
        //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
        query = {
          orgPK: this.publicOrganization,
          templateSK: JSON.parse(data.layoutID).SK,
          item: JSON.stringify(data.item),
          operation: JSON.stringify(component.metaJSON)
        }
        console.log(' >> Push Data <<\n', query);
        //Mutation
        pushData = await API.graphql({
          query: insuranceTitle_operations, //smd_updateSmartQuiz
          variables: query
        });
        pushData = pushData.data.insuranceTitle_operations
        console.log(' >> Successful update <<' ,pushData);
        //console.log(pushData);
        this.titeInsurance = pushData 
        document.getElementById('titleInsurance-'+component.id).innerHTML = pushData.data
        //this.record.item = pushData
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        this.sub= false
        this.loading('close','updateRecord')
      }, 200);
    },
    async saveButton(component) {  
      console.clear();
      this.loading('show', 'updateRecord')
      if (checkInput(this.layout, this.models) == false) {
        loading('close','updateRecord')
        return
      }
      console.log();
      let data = this.record
      console.log('>> Data Item: ',data);
      //console.log('>> Mode: ',mode);
      let query = { }
      let pushData = ''
      try {
        //Set Mode and Config
        //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
        query = {
          shortEntity: 'QUI',
          PK: this.publicOrganization,
          SK:  data.item.SK,
          baseURL: location.host,
          attributesStr: JSON.stringify(this.models) ,
          entity: {PK: '#SMARTDASH', SK: '#ENT#961aa971-3017-4f13-8f52-0b3d513313a3'},
          form: JSON.parse(data.layoutID),
          parent:  data.item.parent,
          usersCanEdit: [],
          usersCanRead: [] ,
          groupsCanEdit:["Admin","Superadmin","Editor"],
          groupsCanRead:["Public"],   
          
        }
      
        if (query.SK == '') {
          query.SK = undefined
          query.PK = this.publicOrganization
        }
        console.log(' >> Push Data <<\n', query);
        //Mutation
        pushData = await API.graphql({
          query: smd_updateSmartQuiz1, //smd_updateSmartQuiz // smd_updateSmartQuiz1
          variables: { 
            input:query,
            action: '{"action":"hola"}'
          },
        });
        pushData = pushData.data.smd_updateSmartQuiz1
        document.getElementById('screen-wrap').classList.add('show')
        console.log(' >> Successful update <<' ,pushData);
        document.getElementById('returnQuiz').innerHTML = pushData.data
        
        //query.response = pushData.smd_response
        //this.setQuiz(query);
        console.log(pushData);
        //this.record.item = pushData
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        this.sub= false
        this.loading('close','updateRecord')
      }, 200);
    },
    inputHtml(component){
      try {
        console.log(document.getElementById('preview-wrap'));
        document.getElementById('preview-wrap-'+component.id).innerHTML = this.models[component.attributeName].attributeValue
      } catch (err) {
        console.log(err);
      }
    },
    searchQuote(component){  
      let value =  document.getElementById('search-'+component.id).value
      let listItems = document.getElementById('list-'+component.id)
      for (let vindex = 0; vindex < listItems.children.length; vindex++) {
        const element = listItems.children[vindex];
        if ((element.innerText.toLowerCase()).includes(value.toLowerCase()) ) {
          document.getElementById('list-'+component.id).children[vindex].classList.remove('displayNone')
        }else{
          document.getElementById('list-'+component.id).children[vindex].classList.add('displayNone')
        }
      }
    },
    async clipboard(component, index, key, blank){
      console.log(this.models[component.attributeName]);
      let url = await Storage.get(this.organizationID+"/"+key, { level: this.models[component.attributeName].attributeValue[index].security,  identityId:this.models[component.attributeName].attributeValue[index].identityId });
      console.log(url);
      this.models[component.attributeName].attributeValue[index].url = url
      if (blank != false) {
        //window.open(url);
        navigator.clipboard.writeText(url);
        
      }
    },
    async fileAction(action, component, index, file){
      console.log(component);
      let url = ''
      switch (action) {
        case 'set':
          if (this.models[component.attributeName].attributeValue[index].security == 'public') {
            url = file.url
          }else{
            console.log([this.organizationID+"/"+file.key, { level: this.models[component.attributeName].attributeValue[index].security,  identityId:this.models[component.attributeName].attributeValue[index].identityId }]);
            url = await Storage.get(this.organizationID+"/"+file.key, { level: this.models[component.attributeName].attributeValue[index].security,  identityId:this.models[component.attributeName].attributeValue[index].identityId });
          }
          this.models[component.attributeName].attributeValue[index].url = url
        break;
        case 'copy':
          console.log(this.models[component.attributeName].attributeValue[index].url);
          navigator.clipboard.writeText(this.models[component.attributeName].attributeValue[index].url)
        break;
      }
    },
    async setLinkAssets(component, index, key){
      let url = await Storage.get(this.organizationID+"/"+key, { level: this.models[component.attributeName].attributeValue[index].security, identityId:this.models[component.attributeName].attributeValue[index].identityId });
      this.models[component.attributeName].attributeValue[index].url = url
    },
    validationValue(component){
      if (this.models[component.attributeName].attributeValue == '' ||  this.models[component.attributeName].attributeValue == undefined ) {
        this.models[component.attributeName].attributeValue = 0
      }
    },
    setIdText(data){
      if ((data && data != null) && data.startsWith('<')) {
        console.log(data.replace(/['"]+/g, ''))
        data = $('<div>').html(data).text();
        data = data.replace(/['"]+/g, '')
      }
      return data
    },
    setValueFront(component, models, id){
      let data = component.elementType
      let gLanguage = this.gLanguage
      
      try {
        function checkValue(bind, component, att) {
          if (bind[component.attributeName]) {
            if (bind[component.attributeName].attributeValue ) {
              try {
                if (component.elementType.toLowerCase() == "img" || component.elementType.toLowerCase() == "video") {
                  data = JSON.parse(bind[component.attributeName].attributeValue)[0].url.replace(/#/gi,'%23')
                  if(window.innerWidth < 767){
                    data = JSON.parse(bind.imagesMobile.attributeValue)[0].url.replace(/#/gi,'%23')
                  }
                  if (component.elementType.toLowerCase() == "img" && component.customImg == true ) {
                    data = JSON.parse(bind[component.attributeName].attributeValue)[component.noImg].url.replace(/#/gi,'%23')
                  }
                }else{
                  data = bind[component.attributeName].attributeValue
                  try{
                    if (data.startsWith('<') && component.elementType.toLowerCase() == 'text') {
                      setTimeout(() => {
                        console.log('---------------------')
                        console.log(document.getElementById(id))
                        console.log('id', id, '\n component:', component, '\n bind', bind)
                        console.log(document.getElementById(id).innerHTML)
                        document.getElementById(id).innerHTML = bind[component.attributeName].attributeValue
                        document.getElementById(id+'-p').style = 'display:none;'
                      }, 2000);
                    }
                    console.log(error)
                  }catch(error){

                  }
                }
              } catch (error) {
                console.log(error)
                data = component.url
              }
            }else{
              if (typeof bind[component.attributeName] == 'string') {
                data = bind[component.attributeName]
              }else{
                data = null
              }
            }
          }else{
            if (att == 'text') {
              try {
                data = component[att][gLanguage]
              } catch (error) {
                data = '_'+component[att]
              }
            }else{
              data = component[att]
            }
            //console.log(component[att]);
          }
        }
        if (models.entityName == '') {
          
        }
        switch (component.elementType) {
          case 'Title':
            checkValue(models, component, 'text'+[]);
          break;
          case 'Text':
            checkValue(models, component, 'text');
          break;
          case 'Img':
            checkValue(models, component, 'url');
          break;
          case 'img':
            checkValue(models, component, 'url');
          break;
          case 'video':
            checkValue(models, component, 'url');
          break;
          case 'shape':
            checkValue(models, component, 'text');
          break;
          case 'link':  
            switch (models.shortEntity) {
              case 'BLO':
                data = models.path.attributeValue
              break;
              case 'PRO':
                data = models.path.attributeValue
              break;
              default:
                data = component.url
                checkValue(models, component, 'url');
                if (component.linkType) {
                  switch (component.linkType) {
                    case 'phone':
                      data = models[component.attributeName]
                      data = 'tel:'+data
                      console.log(data);
                    break;
                    case 'email':
                      data = models[component.attributeName]
                      data = 'mailto:'+data
                    break;
                    default:
                    break;
                  }
                }
              break;
            }
          break;
        }
        
      } catch (error) {
        data = 'loading...'
        console.log(error);
        console.log(models);
        console.log(models[component.attributeName]);
        
      }
      //console.log(data);
      return data
    },
    setElementComputed(component){
      //console.clear();
      let i = i++
      console.log('setElementComputed------------------- \n '+component.attributeName+'\n ');
      let value = ''
      let formula = ''
      if (component.formula.includes('+') || component.formula.includes('-') || component.formula.includes('x') || component.formula.includes('/')) {
        try {
          value = component.formula
          for (const key in this.models) {
            if (component.formula.includes(key)) {  
              console.log(key);
              if (this.models[key].value == '') {
                value = value.replace(key, '0' )   
              }else{
                value = value.replace(key,this.models[key].value )   
              }
              console.log(key +': '+this.models[key].value);
            }
          }
          console.log(value);
          value = eval(value)
          this.models[component.attributeName].attributeValue = value
        } catch (error) {
          console.log(error);
        }
      }else{
        console.log(component.formula);
        value = this.models
        formula = component.formula.split(':')
        try {        
          for (let index = 0; index < formula.length; index++) {
            value = value[formula[index]]
          }
          this.models[component.attributeName].attributeValue = value
        } catch (error) {
          console.log(error);
        }
      }
    },
    setClass(component){
      let wrap = 'undefined'
      try {
        if (component.metaType) {
          wrap = component.metaType.replace('meta','').toLowerCase()+'-wrap'
          if (component.readOnly == true ||component.edit == false ||  this.models[component.attributeName].complete == true) {
            //wrap = wrap + ' readOnly'
          }
          if (component.format != undefined && component.format != '' ) {
            wrap = wrap +" "+component.format
          }
          if (component.read == false) {
            wrap = 'displayNone'
          }
          if (component.smartInput == true) {
            wrap = wrap +" input-smart"
          }
        }
      } catch (error) {
        //console.log(component ,error);
      }
      return wrap
    },
    setStyle(component, type){
      let style = ''
      let responsive = 0
      //srcFonts
      let font = ''
      let src = {}
      try {
        
        if (window.screen.width < 530) {
          responsive = 3
        } else if(window.screen.width < 767){
          responsive = 2
        } else if(window.screen.width < 992){
          responsive = 1
        }
        if (this.$router.currentRoute.value.path != '/dashboard') {
          src.palette = this.palette
        }else{
          src.palette=[ ]
          src.fonts=[ ]
        }
        
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      
      try {
        if (typeof component.style.font.font == 'number') {
          font = this.srcFonts[component.style.font.font]
        }
        if (typeof component.style.font.font == 'string') {
          font = src.fonts[component.style.font.font]
        }
        font = src.fonts[component.style.font.font]
      } catch (error) {
        //console.log(error);
      }
      //
      if (this.models.responsive) {
        responsive = this.models.responsive
      }
      //
      try {
        let varFont = ''
        if (location.pathname == "/smartmaker") {
          if (typeof component.style.font.font == 'string') {
            switch (component.style.font.font) {
              case 'Title': component.style.font.font = 0; break;
              case 'SubTitle': component.style.font.font = 1; break;
              case 'Text': component.style.font.font = 2; break;
              case 'SubText': component.style.font.font = 3; break;
            }
          }
          style = processStyle( component , type , this.webSite , responsive, )
        }
        else{
          style = processStyle( component , type , this.webSite , responsive, true)
        }
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      try {
        if (this.models[component.attributeName].complete == true) {1
          style = style + 'background:#75df084a!important ;'
        }
        if (this.filterSmarForm != 'all') {
          if (this.filterSmarForm == 'incomplete' ) {
            if (this.models[component.attributeName].complete == true) {
              style = 'display: none!important;'
            }
          }
          if (this.filterSmarForm == 'complete' ) {
            if (this.models[component.attributeName].complete != true ) {
              style = 'display: none!important;'
            }
          }
        }
      } catch (error) {
        //console.log(error);
        //popUp(error, 'cach', 'err')
      }
      let palette = this.palette
      let regexp = /\[.+?\]/g;
      let bind = [...style.matchAll(regexp)];
      for (let index = 0; index < bind.length; index++) {
        let parameter = JSON.parse(bind[index][0])
        style = style.replace(bind[index][0], palette[parameter[1]])
      }
      return style
    },
    setEditor(component, index , td ){
      if (component.inputType == 'quote') {
        try {
          setTimeout(() => {
            $(`#editor${index+'-'+td}`).trumbowyg({
              svgPath: false,
              hideButtonTexts: true
            });
            document.getElementById(`editor${index+'-'+td}`).innerHTML = unescape(this.models[component.attributeName].attributeValue.data[td]['product.description'])
          }, 100);
        } catch (error) {
          
        }
      } else {
        try {
          setTimeout(() => {
            $(`#editor${index}`).trumbowyg({
              svgPath: false,
              hideButtonTexts: true
            });
            document.getElementById(`editor${index}`).innerHTML = unescape(this.models[component.attributeName].attributeValue)
          }, 100);
        } catch (error) {
          
        }
      }
    },
    setDataEditor(component,index, td){
      if (component.inputType == 'quote') {
        try {
          setTimeout(() => {
            this.models[component.attributeName].attributeValue.data[td]['product.description'] = document.getElementById(`editor${index+'-'+td}`).innerHTML
          }, 100);
        } catch (error) {
          
        }
      } else {
        try {
          setTimeout(() => {
            this.models[component.attributeName].attributeValue = document.getElementById(`editor${index}`).innerHTML
          }, 100);
        } catch (error) {
          
        }
      }
    },
    setDataEditor2(component, data, action){
      switch (action) {
        case 'new':
          setTimeout(() => {
            document.getElementById('visuell-view-'+component.id).innerHTML = data
          }, 100);
        break;
        case 'click':
          setTimeout(() => {
            this.models[component.attributeName].attributeValue =  data
          }, 100);
        break;
      }
    },
    setDataEditorDiv: function(e) {
        console.log(e );
    },
    setValueLayout(component){
      //console.log(this.models);
      let w_temp = {}
      //console.clear()

      try {
        for (let index = 0; index < component.smartComponents.length; index++) {
          const element = component.smartComponents[index];
          w_temp[element.attributeName] = this.models[element.attributeName].attributeValue
        }
      } catch (error) {
        console.log(error);
      }
      //console.log(w_temp);
      return w_temp
    },
    setHeaders(index){
      if (this.layout[index].headers.length <= 0 ) {
        return [{label: this.layout[index].attributeName+'', attributeName: this.layout[index].attributeName, attributeType: 'Text' }]
      }
      else{
        return this.layout[index].headers
      }
    },
    f_searchSelect(vindex){
      let list = document.getElementById("dropdown"+vindex);
      for (let i = 2; i < list.children.length; i++) {
        let txtValue = list.children[i].textContent || list.children[i].innerText;
        if (txtValue.toLowerCase().indexOf(this.searchSelect.toLowerCase()) > -1) { 
          document.getElementById("dropdown"+vindex).children[i].style.display = "";
        } else {
          document.getElementById("dropdown"+vindex).children[i].style.display = "none";
        }
      }
    },
    setValueSelect(index, value, component, location, data ){
      console.clear()
      let w = value
      if (typeof data == 'object') {
        w = data
      }
      
      try {
        if (this.models[component.attributeName].attributeValue == w) {
          console.log('if');
          this.models[component.attributeName].attributeValue = ''
        }else{
          console.log('else', [index, value, component, location, data,w]);
          if (this.layout[index].metaType == 'metaInput') {
            if (this.layout[index].isRepeatable == true) {
              console.log('Es bingo');
              this.layout[index].itemTable = w
              this.anchor++
            }else{
              console.log('Normal input, value : ', w,component)
              delete w.new
              // If-
              if (component.allowComplete && component.allowComplete == true) {
                console.log(component, w);
                for (const key in w.attributes) {
                  console.log(key);
                  if (this.models[key]) {
                    this.models[key] = w.attributes[key] 
                  }
                }
              }
              
              this.models[component.attributeName].attributeValue = {...w}
              if (this.models[component.attributeName].attributeValue.attributes) {
                
                delete this.models[component.attributeName].attributeValue.attributes
              }
            }
            if (this.layout[index].inputType == 'preloadList') {
              this.models[component.attributeName].attributeValue[location[0]] =  {...w}
            }
          }
          if (this.layout[index].metaType == 'metaLayout') {
            this.anchor++ 
            if (w.new) {
              delete w.new
              console.log(value[value.length -1]);
              this.layout[index].smartComponents[value[value.length -1]].valueOptions.push(w)
            }
            this.models[component.attributeName].attributeValue[location[0]][location[1]] = {...w}
            if (this.models[component.attributeName].attributeValue[location[0]][location[1]].attributes) {
              delete this.models[component.attributeName].attributeValue[location[0]][location[1]].attributes
            }
          }
          console.log( this.models[component.attributeName].attributeValue );
        }
        this.searchSelect= ''
        this.f_searchSelect(component.id)
      } catch (error) {
        console.log(error);
      }
    },
    unSetValueSelect(component, value){
      if (this.models[component.attributeName].attributeValue == value) {
        return 'cursor:pointer; background: #0057ff; color:#fff;'
      }else{
        return 'cursor:pointer;'
      }
      
    },
    help(component) {
      console.log(component);
      let help = document.getElementById('help'+component.id).classList;
      document.getElementById('help'+component.id).innerHTML = unescape(component.help)
    },      
    alert(tag, index) {
      let help = document.getElementById(tag).classList;
      if (help[1] == "show") {
        document.getElementById(tag).classList.remove("show")
      }else[
        document.getElementById(tag).classList.add("show")
      ]
    },    
    f_itemValueCombo(item, column, parent) {
        let td = ""
        if (parent == true) {
        td = item[column.parent][column.attributeName]
          try {
            if (td) {
              td = JSON.parse(td)
            }
          } catch (error) {
          }

        }else{
          if (item[column]) {
            td = item[column]
          }else{
            td = "..."
          }
        }
        return  td
    },
    f_totals(component){  
      let totals = { 
        subTotal:0,
        discount:0,
        total:0,
      }
      try {
        let data = this.models[component.attributeName].attributeValue.data
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          totals.subTotal = totals.subTotal + parseInt(element['product.price'])
          if (element['product.discount'] == '' || element['product.discount'] == undefined) {
            this.models[component.attributeName].attributeValue.data[index]['product.discount'] = 0
          }
          totals.discount = totals.discount + parseInt(element['product.discount'])
        }
        totals.total = totals.subTotal - totals.discount
        this.models[component.attributeName].attributeValue.totals = totals
        return totals
      } catch (error) {
        console.log(error);
      }
    },
    styleWrap(index){
      if (this.layout[index].isRepeatable == true && this.layout[index].metaType == 'metaInput') {
        return 'padding: 0px 40px 0px 0px;'
      }
    },
    f_styleInpu(index){
      if (this.layout[index].readOnly == true) {
        return 'border:none; cursor:default;'
      }
    },
    f_repeatableAction(vindex, action , component, tdIndex ) {
      console.clear()
      console.log('> Action: '+action + ' > vindex: '+vindex);
      switch (action) {
        case 'push':
          console.log('> Meta Type: ', this.layout[vindex].metaType);
          console.log('> Component Attribute Name: '+component.attributeName);
          console.log('> Model: ' ,this.models[component.attributeName]);
          if (this.models[component.attributeName].attributeValue == undefined ) {
            this.models[component.attributeName].attributeValue = []
          }
          if (this.layout[vindex].metaType == 'metaLayout') {            
            if (!this.models[component.attributeName].attributeValue.length ) {
              this.models[component.attributeName].attributeValue = []
            }
            
            this.models[component.attributeName].attributeValue.push({})
            this.layout[vindex].headers.forEach(element => {
              this.models[component.attributeName].attributeValue[this.models[component.attributeName].attributeValue.length-1][element.attributeName] = ''
              if (element.attributeType == "createdBy") {
                this.models[component.attributeName].attributeValue[this.models[component.attributeName].attributeValue.length-1][element.attributeName] = this.user
              }
            });
          }
          if (this.layout[vindex].metaType == 'metaInput') {
            if (this.layout[vindex].inputType == 'GRAPHQLPLUS') {
              if (this.layout[vindex].itemTable) {
                console.log('before'+JSON.stringify(this.models[component.attributeName].attributeValue, null, 4));
                if (!this.models[component.attributeName].attributeValue.data) {
                  console.log('bad');
                  this.models[component.attributeName].attributeValue = {data:[],totals:[]}
                }
                this.models[component.attributeName].attributeValue.data.push(this.layout[vindex].itemTable)
                this.layout[vindex].itemTable = ''
                console.log('after'+JSON.stringify(this.models[component.attributeName].attributeValue, null, 4));
              }
              else{
                console.log(this.layout[vindex].itemTable);
                console.log(' No hay Valor en select');
              }
            }
            else{
              this.models[component.attributeName].attributeValue.push(null)
            }
          }
          if (this.layout[vindex].attributeName == 'SMLPhones') {
            this.models[component.attributeName].attributeValue[this.models[component.attributeName].attributeValue.length-1].countryCode = '+1'
          }
        break;
        case 'push-internalTable':
          console.log(JSON.stringify(this.layout[vindex].metaType, null, 4));
          console.log(component.attributeName);
          if (this.models[component.attributeName].attributeValue == undefined || !this.models[component.attributeName].attributeValue.length) {
            this.models[component.attributeName].attributeValue = []
          } 
          let tempModel = { }
          tempModel.SK = undefined
          tempModel.PK = undefined
          tempModel.read =true
          tempModel.edit =true
          tempModel.shortEntity = this.layout[vindex].shortEntity
          tempModel.attributes = {}
          tempModel.form= {
            PK: this.layout[vindex].form.PK,
            SK: this.layout[vindex].form.SK
          },
          tempModel.entity={
            PK: this.layout[vindex].entity.PK,
            SK: this.layout[vindex].entity.SK
          } 
          for (let index = 0; index < component.smartComponents.length; index++) {
            const element = component.smartComponents[index]
            console.log(component.smartComponents[index].attributeName);
            tempModel.attributes[element.attributeName] ={
              value: '',
              label: element.label,
              inputType: element['metaInput.inputType'],
              attributeType: 'S',
            }
          }
          console.log(tempModel);
          console.log(JSON.stringify(tempModel, null, 2));
          console.log(JSON.stringify(this.models[component.attributeName], null, 2));
          this.models[component.attributeName].push(tempModel)

        break;
        case 'pushQuote':
          console.log(this.models[component.attributeName].attributeValue.data);
          const newItem = { ...this.products[tdIndex] };
          console.log(this.goodFormat(newItem, 'json'));
          console.log(newItem);
          if(this.models[component.attributeName].attributeValue.data == undefined ){
            this.models[component.attributeName].attributeValue = 'EMPTY:  Used in deep objects'
            this.models[component.attributeName].attributeValue = {}
            this.models[component.attributeName].attributeValue.data = []
            this.models[component.attributeName].attributeValue.data.push(newItem)
            //this.models[component.attributeName].attributeValue.data.splice(0, 0, newItem)
          }else{
            //this.models[component.attributeName].attributeValue.data.splice(0, 0, newItem)
            this.models[component.attributeName].attributeValue.data.push(newItem)
          }
          this.f_totals(component)
          this.anchor +1
        break;
        case 'pushSteps':
          let pushContent =  { days:0, amount: 0,  notes:'', }
          //Caclculate Amount
          console.log(this.models[component.attributeName].attributeValue.steps);
          if (this.models[component.attributeName].attributeValue.steps == undefined || this.models[component.attributeName].attributeValue.steps.length == 0) {
            this.models[component.attributeName].attributeValue.steps = []
            pushContent.amount = this.models[component.attributeName].attributeValue.totals.total
            this.models[component.attributeName].attributeValue.steps.push(pushContent)
          }else{
            let steps = this.models[component.attributeName].attributeValue.steps
            console.log(this.models[component.attributeName].attributeValue.totals.total / steps[0].amount +' - '+ steps.length);
            let equal = true
            for (let index = 0; index < steps.length; index++) {
              let adj = Math.floor( this.models[component.attributeName].attributeValue.totals.total / steps.length)
              console.log(adj + ' - ' + steps[index].amount);
              if (adj == steps[index].amount || adj == steps[index].amount+1 || adj == steps[index].amount-1 ) {
                //equal = true
              }else{
                equal = false
              }
              console.log(equal);
            }
            if (equal == true) {
              console.log('son equitativos');
              this.models[component.attributeName].attributeValue.steps.push(pushContent)
              let tmp_amount = 0
              for (let index = 0; index < steps.length; index++) {
                this.models[component.attributeName].attributeValue.steps[index].amount = Math.floor(this.models[component.attributeName].attributeValue.totals.total / steps.length)
                tmp_amount = tmp_amount + Math.floor(this.models[component.attributeName].attributeValue.totals.total / steps.length) 
              
              }
              if (tmp_amount < this.models[component.attributeName].attributeValue.totals.total ) {
                let adjustment = this.models[component.attributeName].attributeValue.totals.total - tmp_amount
                console.log(adjustment);
                this.models[component.attributeName].attributeValue.steps[steps.length-1].amount = this.models[component.attributeName].attributeValue.steps[steps.length-1].amount + adjustment
              }

            }else{
              let diference = this.models[component.attributeName].attributeValue.totals.total
              for (let index = 0; index < steps.length; index++) {
                diference = diference - steps[index].amount
              }
              pushContent.amount = diference
              this.models[component.attributeName].attributeValue.steps.push(pushContent)
            }
          }
        break;
        case 'deleteSteps':
          this.models[component.attributeName].attributeValue.steps.splice(tdIndex, 1)
        break;
        case 'delete':
          if (this.layout[vindex].metaType == 'metaLayout') {            
            this.models[component.attributeName].attributeValue.splice(tdIndex, 1)
          }
          if (this.layout[vindex].metaType == 'metaInput') {
            if (this.layout[vindex].inputType == 'GRAPHQLPLUS' || this.layout[vindex].inputType == 'quote' ) {
               this.models[component.attributeName].attributeValue.data.splice(tdIndex, 1)
            }
            else{
              this.models[component.attributeName].attributeValue.splice(tdIndex, 1)
            }
          }
          this.f_totals(component)
        break;
      }
      this.anchor++
    },
    f_checks(status, index) {
      console.log(status);
      console.log(index);
      console.log('----------------------------------------------------------------------------');
    },
    f_radioSingle(name){
      //console.log(this.models[name].value);
    },
    stringify(value) {
      console.log(JSON.stringify(value, null, 4));
      return JSON.stringify(value, null, 4)
    },
    f_validationName(value, target){
      try {
        if (typeof value[target] != 'object' ) {
          return JSON.parse(value[target])
        }else{
          return value[target]
        }
      } catch (error) {
        console.log(error)
      }
    },
    async createRecordByGraphQl(component, id, a,b,c,d){
      console.log(component);
      if (component.gqlVersion && component.gqlVersion == 2) {
        console.clear()
        var save = new Event('click');
        let buttonSave = document.getElementById('saveClose-'+this.data.SK);
        buttonSave.dispatchEvent(save);
        let data = this.entities[component.metaJSON.shortEntity]
        let item = {
          SK : 'ADD',
          PK: this.organizationID,
          form: { PK: JSON.parse(data['metaEntity.defaultFormLayout']).PK, SK: JSON.parse(data['metaEntity.defaultFormLayout']).SK},
          shortEntity: component.metaJSON.shortEntity,
          entity: { PK: data.PK, SK: data.SK},
        }
        this.setbRecord([ item, 'new', a,component,c,d])
      }else{
        console.log(id);  
        console.log(document.getElementById(id));
        let query = { }
        let pushData = ''
        try {
          //Set Mode and Config
          //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
          query = {
            shortEntity: component.metaJSON.shortEntity,
            PK: this.organizationID,
            SK: undefined,
            attributesStr: JSON.stringify(
              {
                [component.metaJSON.fieldValue]:{
                  attributeName: component.metaJSON.fieldValue,
                  attributeType:'S',
                  attributeValue: document.getElementById(id).value,
                }
              }
            ) ,
            entity: {
              PK:this.entities[component.metaJSON.shortEntity].PK,
              SK:this.entities[component.metaJSON.shortEntity].SK
            },
            form: {},
            parent:  undefined,
            usersCanEdit: [],
            usersCanRead:  [],
            groupsCanEdit:  ['Admin', 'Superadmin', 'Editor'],
            groupsCanRead:  ['Admin', 'Superadmin', 'Editor'],
          }
          if (query.SK == '') {
            query.SK = undefined
          }
          console.log(' >> Push Data <<', query);
          //Mutation
          pushData = await API.graphql({
            query: smd_updateSmartRecord, //smd_updateSmartRecord
            variables: { 
              input:query
            },
          });
          pushData = pushData.data.smd_updateSmartRecord
          pushData = JSON.parse(pushData.data)
          let data = {
            PK:pushData.PK,
            SK:pushData.SK,
            value:document.getElementById(id).value,
            new:true
          }
          console.log(' >> Response update <<' ,pushData);

          this.setValueSelect(a, b , c, d , data)
          return
        } catch (error) {
          console.log(error);
          popUp(error, 'cach', 'err')
        }
      }
    },
    //file Down and Up  
    async fileUp(e){
      console.clear()
      this.loading('show');
      //File Config
      let event = e
      try {
        let name = event.target.value.replace('C:\\fakepath\\', '')
        let extension = name.split('.')[name.split('.').length-1]
        let data = {
          name: name,
          extension: extension,
          key: uuidv4()+"."+extension,
          securityFile: 'public',
          file: event.target.files[0],
          component: this.layout[event.target.id],
          index: event.target.id
        }

        if (this.layout[data.index].securityLevel != 'open') {
          this.securityFile = this.layout[data.index].securityLevel
          data.securityFile = this.layout[data.index].securityLevel
        }
        if (data.file) {
          let result = await Storage.put(
            this.organizationID+"/"+data.key,
            data.file,
            {
              contentDisposition: "inline",
              contentType: data.file.type,
              level: data.securityFile,
            }
        );
          this.closeModal('modal-file-'+this.layout[data.index].id)
          await this.f_fileConstructor(data )
        }
        var save = new Event('click');
        let buttonSave = document.getElementById('save-'+this.data.SK);
        console.log(buttonSave);
        buttonSave.dispatchEvent(save);
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      this.loading('close');
    },
    async f_fileConstructor(data){
      try {

        if (typeof this.models[data.component.attributeName].attributeValue != 'object') {
          this.models[data.component.attributeName].attributeValue= []
        }
        let url = ''
        if (data.securityFile == 'public') {
          url = 'https://smartdashs3120108-dev.s3.amazonaws.com/public/'+ this.organizationID.replace('#','%23')+"/"+data.key
        }else{
          url = this.organizationID+"/"+ data.key
        }
        this.models[data.component.attributeName].attributeValue.push({
          name: data.name,
          url: url,
          key: data.key, 
          security: data.securityFile, 
          description: this.descriptionFile,
          identityId:this.identityId,
          route: data.securityFile+'/'+this.identityId+'/'+this.organizationID+"/"+ data.key,
          createdAt: new Date().toISOString(),
        })
        this.descriptionFile = ''
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }

    },
    async fileDelete( file, index, indexItem ){
      this.loading('show');
      let component = this.layout[index] 
      if (file == "") {
      }else{        
        //await Storage.remove(this.organizationID+"/"+file.key, { level: file.security });
        let buttonSave = document.getElementById('save-'+this.data.SK);
        var event = new Event('click');
        try {          
          for (let vindex = 0; vindex < this.models[component.attributeName].attributeValue.length; vindex++) {
            console.log(vindex)
            if (file.key == this.models[component.attributeName].attributeValue[vindex].key) {
              this.models[component.attributeName].attributeValue.splice([vindex], 1)
            }
          }
          buttonSave.dispatchEvent(event);
        } catch (error) {
          console.error(error)
          this.models[this.layout[index].attributeName].attributeValue
        }
      }
      this.loading('hide');
    },
    //Internal Table
    async setStatus(item, component, action, index) {
      console.log(item.PK, item.SK);
      let status = ''
      let push =''
      if (action == 'active') {
        status ='1'
      }
      if (action == 'desactive') {
        status= '0'
      }
      if (action == 'archive') {
        status = '2'
      }
      console.log(action+'-'+status);
      try {        
        push = await API.graphql({
          query: smd_setItemState,
          variables: { PK: item.PK, SK: item.SK, state: status},
        });
        console.log(push);
        this.models[component.attributeName].externalValue.splice(index, 1);
      } catch (error) {
        console.log(error);
      }
    },
    valueInternalTable(model) {
      let value = ''
      if (model.value) {
        value = model.value
      }
      return value
    },
    async editInternalTable( component, SK, comand ){
      //console.clear()
      let buttonSave = document.getElementById('save-'+this.data.SK);
      console.log(buttonSave);
      var event = new Event('click');
      console.log("--> Component :",this.goodFormat(component, 'json'));
      console.log(SK);
      console.log(this.itemPK);
      console.log(this.itemSK);
      console.log("--> itemView :", this.goodFormat(this.itemView, 'json'));
      let models = this.modelsInternalTable 

      for (const key in models) {
        try {
          if (models[key].type == 'L' &&  models[key].value != '') {
            models[key].value = JSON.stringify(models[key].value)
          }
          if (models[key].type == 'HTML' && models[key].value != '') {
            models[key].value = escape(models[key].value)
          }
        } catch (error) {
          console.error(error);
        }
      }

      console.log("--> data :", this.goodFormat(this.data, 'json'));
      let push = ''
      try {
        push = await API.graphql({
          query: smd_updateSmartRecord, //smd_updateSmartRecord
          variables: { 
            input:{
              PK: this.data.PK ,
              SK: SK ,
              attributesStr: JSON.stringify(models, null, 4),
              form: {
                PK:this.itemView.form.PK,
                SK:this.itemView.form.SK,
              },
              entity: {
                PK:this.itemView.entity.PK,
                SK:this.itemView.entity.SK,
              },
              shortEntity: this.itemView.shortEntity,
              parent: this.data,
              usersCanEdit: this.paperPermissions.usersCanEdit,
              usersCanRead: this.paperPermissions.usersCanRead,
              groupsCanEdit:this.paperPermissions.groupsCanEdit,
              groupsCanRead:this.paperPermissions.groupsCanRead,
            }
          },
        });
        push = push.data.smd_updateSmartRecord
        console.log(component.attributeName);
        console.log(push);
        if (SK == undefined) {
          this.models[component.attributeName].externalValue.push({
            attributes:this.modelsInternalTable,
            PK: push.PK,
            SK: push.SK,
            edit: true,
            read: true,
            entity: component.entity,
            form: component.form,
          })
        }
        console.log('----------------------------------------');
        this.itemView.SK = push.PK
        this.itemView.PK = push.SK
        if (comand == 'close') {
          console.log(document.getElementById('modal-'+component.id));
          this.closeInternalModal(component)
        }
      } catch (error) {
        console.log(error);
      }
      for (const key in this.modelsInternalTable) {
        try {
          //console.log(key+': ',this.models[key]);
          if (this.modelsInternalTable[key].type == 'L' && this.modelsInternalTable[key].value != '') {
            this.modelsInternalTable[key].value = JSON.parse(this.modelsInternalTable[key].value)
          }
        } catch (error) {
          console.error('--> Error \n '+ key+': ',this.goodFormat(this.modelsInternalTable[key], 'json') );
        }
      }
      buttonSave.dispatchEvent(event);
    },
    //Change
    closeInternalModal(component){
      $('#modal-'+component.id).modal('hide');
    },
    closeModal(id){
        $('#'+id).modal('hide');
    },
    async getItemInternalTable(component, parent, item, index){
      console.log(item);
      console.log(JSON.stringify(this.models[component.attributeName], null ,4));
      let pullQuery = ''
      try {
        if (item == null) {
          pullQuery = await API.graphql({
            query: smd_getSMLItem,
            variables: {
              SMLPK: component.form.PK,
              SMLSK: component.form.SK,
              parent: {
                PK: parent.PK,
                SK: parent.SK
              },
              entity:{
                PK: component.entity.PK,
                SK: component.entity.SK,
              }
            },
          });
        }else{
          console.log(item.SK);
          pullQuery = await API.graphql({
            query: smd_getSMLItem,
            variables: {
              itemPK:item.PK,
              itemSK:item.SK,
              SMLPK: component.form.PK,
              SMLSK: component.form.SK,
              parent: {
                PK: parent.PK,
                SK: parent.SK
              },
              entity:{
                PK: component.entity.PK,
                SK: component.entity.SK,
              }
            },
          });
        }
        console.log('> Data Query ', pullQuery.data.smd_getSMLItem);
        pullQuery = pullQuery.data.smd_getSMLItem
        this.itemView = JSON.parse(pullQuery.item)
        if (item == null ) {
          this.itemView.SK = undefined
        }
        console.log('--> Item:',this.itemView);
        this.layoutInternalTable = JSON.parse(pullQuery.data)
        console.log('--> Layout:',this.layoutInternalTable);
        this.modelsInternalTable =this.itemView.attributes
        console.log('--> Models:',this.modelsInternalTable);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...Vuex.mapState(["gRecord","entities","organizationID","identityId", "pageForm", "pageTable", "pageName",'srcFonts', "pageQuery", "entity", "pageMeta", "pageModule", "permissions", "userPermissions", 'recordorganizationData', 'publicOrganization','publicOrganizationData', 'cartData', 'organizationData','preloadLists','recordLists','products', 'gLanguage','webSite','user','choice']),
    elementComputed:{
      get: function () {
        return 'gggg'
      },
      // setter
      set: function (newValue) {
        console.log(newValue);
      }
    },
    dragOptions() { 
      return {
        animation: 200,
        group: "inputs",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
}
</script>