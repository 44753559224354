<template>
   <div class="smart-wrap">
      <div class="element-wrap table-wrap" >
         <table class=" table" id="myTable">
            <thead>
               <slot name="head">

               </slot>
            </thead>
            <tbody>
               <slot name="body">
                  
               </slot>
            </tbody>
            <slot name="tbody">
                  
            </slot>
         </table>
         <slot name="footer">

         </slot>
      </div>
   </div>
</template>
<script>
//Tools
import Vuex from 'vuex'
import { formatData, loading } from '../../store/tools.js';
import { v4 as uuidv4 } from 'uuid';  
//Components
import PaperDisplayer from '../papers/PaperDisplayer.vue'
export default {
   name: 'ui-table',
   components:{
      PaperDisplayer
   },
}
</script>
<style lang="">
   
</style>