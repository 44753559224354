<template>
  <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade modal-g" :id="'modal-'+name" tabindex="-1" role="dialog" :aria-labelledby="'modal-'+name"  aria-hidden="true">
      <div :class="'modal-dialog ' + type +' '+classModal" :id="name" role="document">
        <div class="modal-content">
          <div class="m-header">
            <slot name="header">
            </slot>
          </div>
          <div class="m-content">
            <slot name="content">
              <div v-if="type =='file'" class="smart-wrap">
                <div class="input-wrap col-md-12">
                  <label for="">Description</label>
                  <textarea name="" id="descriptionFile" cols="10" rows="4" ></textarea>
                </div>
                <div class="input-wrap col-md-12">
                  <input v-on:change="fileUp( $event)" type="file" multiple name="" :id="index" ref="refFile" >
                </div>
              </div>
            </slot>
          </div>
          <div class="m-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
//Tools
import Vuex from 'vuex'
import { formatData, loading } from '../../store/tools.js';
import { v4 as uuidv4 } from 'uuid';  
//Components
import PaperDisplayer from '../papers/PaperDisplayer.vue'

export default {
  name: 'ui-modal',
  components:{
    PaperDisplayer,
  },
  props:{
    type:{
      type: String,
      default: 'type'
    },
    name:{
      type: String,
      default: 'name'
    },
    classModal:{
      type: String,
      default: ''
    },
    data:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      color:'#ccc',
    }
  },
  created() {
  },
  methods: {
    //Modal Basics
    close(name){
      $('#modal-'+name).modal('hide')
    },
    //Modal File
    async fileUp(e){
      loading('show', 'file Up');
      let pre_key = uuidv4()
      let name = e.target.value.replace('C:\\fakepath\\', '')
      let extension = name.split('.')
      let key = pre_key+"."+extension[1]
      let file = e.target.files[0]
      let buttonSave = document.getElementById('save-'+this.record.item.SK);
      console.log(buttonSave);
      console.log(this.targetFile[1].securityLevel);
      var event = new Event('click');
      console.log(file);
      
      if (file == "undefined" || file == undefined || file == null) {
        console.log('Error, no se ha subido un archivo');
      }else{      
        try {
          let result = null
          result = await Storage.put(this.organizationID+"/"+key, file,{contentDisposition: "inline", contentType: file.type, level: this.targetFile[1].securityLevel,});
          console.log(result);
          //let url = await Storage.get(this.organizationID+"/"+key, { level: 'public' });
          console.log('Subido');
          //await this.f_fileConstructor(name, key, index, e.target )
        } catch (error) {
          console.log('Error uploading file:\n ', error);
        }
      }
      console.log(this.$refs)
      this.close(this.name)
      loading('close');
      //buttonSave.dispatchEvent(event);
    },
  },
  computed: {
    ...Vuex.mapState(["organizationData","organizationID", 'record','targetFile' ]),
  },
}
</script>
